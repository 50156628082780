import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Packages from "./packages";
import CountUp from "react-countup";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "components/assets/css/landing.css";

import HmLogo from "components/assets/media/logo-print/HM.jpg";
import A2kLogo from "components/assets/media/logo-print/a2k.png";
import FlashshipLogo from "components/assets/media/logo-print/flashship.svg";
import GearmentLogo from "components/assets/media/logo-print/gearment.png";
import LenfulLogo from "components/assets/media/logo-print/lenful.png";
import MangoLogo from "components/assets/media/logo-print/mangotee.png";
import OnospodLogo from "components/assets/media/logo-print/onospod.jpg";
import PrinteeshubLogo from "components/assets/media/logo-print/printeeshub.png";
import KvLogo from "components/assets/media/logo-print/kv.jpg";
import LogiLogo from "components/assets/media/logo-print/logi.png";

function Main(props) {
    // const { t } = useTranslation();
    var { pathname } = useLocation();

    return (
        <Fragment>
            <div
                id="kt_body"
                data-bs-spy="scroll"
                data-bs-target="#kt_landing_menu"
                className="bg-body position-relative"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="mb-0" id="home">
                        <div
                            className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                            style={{
                                backgroundImage: `url(${
                                    require("components/assets/media/svg/illustrations/landing.svg").default
                                })`,
                            }}
                        >
                            <div
                                className="landing-header"
                                data-kt-sticky="true"
                                data-kt-sticky-name="landing-header"
                                data-kt-sticky-offset="{default: '200px', lg: '300px'}"
                            >
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center flex-equal">
                                            <button
                                                className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                                id="kt_landing_menu_toggle"
                                            >
                                                <i className="ki-outline ki-abstract-14 fs-2hx"></i>
                                            </button>
                                            <Link to="/">
                                                <img
                                                    alt="Logo"
                                                    src={require("components/assets/media/custom/logo.png")}
                                                    className="logo-default h-60px h-lg-60px"
                                                />
                                                <img
                                                    alt="Logo"
                                                    src={require("components/assets/media/custom/logo.png")}
                                                    className="logo-sticky h-20px h-lg-25px"
                                                />
                                            </Link>
                                        </div>
                                        <div className="d-lg-block" id="kt_header_nav_wrapper">
                                            <div
                                                className="d-lg-block p-5 p-lg-0"
                                                data-kt-drawer="true"
                                                data-kt-drawer-name="landing-menu"
                                                data-kt-drawer-activate="{default: true, lg: false}"
                                                data-kt-drawer-overlay="true"
                                                data-kt-drawer-width="200px"
                                                data-kt-drawer-direction="start"
                                                data-kt-drawer-toggle="#kt_landing_menu_toggle"
                                                data-kt-swapper="true"
                                                data-kt-swapper-mode="prepend"
                                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                                            >
                                                <div
                                                    className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-600 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                                                    id="kt_landing_menu"
                                                >
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link active py-3 px-4 px-xxl-6"
                                                            to="#"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Home
                                                        </Link>
                                                    </div>
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            to="https://cotik.gitbook.io/docs"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            How it Works
                                                        </Link>
                                                    </div>
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            to="/privacy-policy"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Privacy Policy
                                                        </Link>
                                                    </div>
                                                    <div className="menu-item">
                                                        <Link
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            to="#"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Pricing
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-equal text-end ms-1">
                                            <Link
                                                to={
                                                    pathname.indexOf("/extension") > -1
                                                        ? "/admin/extension-etsy"
                                                        : "/admin"
                                                }
                                                className="btn btn-success"
                                            >
                                                Sign In
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
                                <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                                    <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-15">
                                        Build An Tiktok Shop Solutions <br />
                                        with{" "}
                                        <span
                                            style={{
                                                background: "linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)",
                                                WebkitBackgroundClip: "text",
                                                WebkitTextFillColor: "transparent",
                                            }}
                                        >
                                            <span id="kt_landing_hero_text">The Best Ever</span>
                                        </span>
                                    </h1>
                                    <Link to="/admin" className="btn btn-primary">
                                        Try Cotik
                                    </Link>
                                    {/*end::Action*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Clients*/}
                                {/*end::Clients*/}
                            </div>
                        </div>
                        <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    {/* <div className="mb-n10 mb-lg-n20 z-index-2">
                        <div className="container">
                            <div className="text-center mb-17">
                                <h3
                                    className="fs-2hx text-gray-900 mb-5"
                                    id="how-it-works"
                                    data-kt-scroll-offset="{default: 100, lg: 150}"
                                >
                                    How it Works
                                </h3>
                                <div className="fs-5 text-muted fw-bold">
                                    Save thousands to millions of bucks by using single tool <br />
                                    for different amazing and great useful admin
                                </div>
                            </div>
                            <div className="row w-100 gy-10 mb-md-20">
                                <div className="col-md-4 px-5">
                                    <div className="text-center mb-10 mb-md-0">
                                        <img
                                            src={require("components/assets/media/illustrations/unitedpalms-1/2.png")}
                                            className="mh-125px mb-9"
                                            alt=""
                                        />
                                        <div className="d-flex flex-center mb-5">
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                                                1
                                            </span>
                                            <div className="fs-5 fs-lg-3 fw-bold text-gray-900">Jane Miller </div>
                                        </div>
                                        <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                            Save thousands to millions of bucks <br />
                                            by using single tool for different <br />
                                            amazing and great
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 px-5">
                                    <div className="text-center mb-10 mb-md-0">
                                        <img
                                            src={require("components/assets/media/illustrations/unitedpalms-1/8.png")}
                                            className="mh-125px mb-9"
                                            alt=""
                                        />
                                        <div className="d-flex flex-center mb-5">
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                                                2
                                            </span>
                                            <div className="fs-5 fs-lg-3 fw-bold text-gray-900">Setup Your App </div>
                                        </div>
                                        <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                            Save thousands to millions of bucks <br />
                                            by using single tool for different <br />
                                            amazing and great
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 px-5">
                                    <div className="text-center mb-10 mb-md-0">
                                        <img
                                            src={require("components/assets/media/illustrations/unitedpalms-1/12.png")}
                                            className="mh-125px mb-9"
                                            alt=""
                                        />
                                        <div className="d-flex flex-center mb-5">
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                                                3
                                            </span>
                                            <div className="fs-5 fs-lg-3 fw-bold text-gray-900">Enjoy Nautica App </div>
                                        </div>
                                        <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                            Save thousands to millions of bucks <br />
                                            by using single tool for different <br />
                                            amazing and great
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="mt-20 mb-n20 position-relative z-index-2">
                        <div className="container">
                            <div className="text-center mb-17">
                                <h3
                                    className="fs-2hx text-gray-900 mb-5"
                                    id="clients"
                                    data-kt-scroll-offset="{default: 125, lg: 150}"
                                >
                                    Features
                                </h3>
                                <div className="fs-5 text-muted fw-bold">
                                    Save thousands to millions of bucks by using single tool <br />
                                    for different amazing and great useful admin
                                </div>
                            </div>
                            <div className="row g-lg-10 mb-10 mb-lg-20">
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        <div className="mb-7 text-center">
                                            <img
                                                src={require("components/assets/media/illustrations/dozzy-1/3.png")}
                                                className="mh-125px mb-4"
                                                alt=""
                                            />
                                            <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">Product</div>
                                            <div className="text-gray-500 fw-semibold fs-4 text-center">
                                                Combine with AI to process - manage - synchronize products from stores
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        <div className="mb-7 text-center">
                                            <img
                                                src={require("components/assets/media/illustrations/dozzy-1/17.png")}
                                                className="mh-125px mb-4"
                                                alt=""
                                            />
                                            <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                Promotions
                                            </div>
                                            <div className="text-gray-500 fw-semibold fs-4 text-center">
                                                Synchronize promotional campaigns, quickly update, track discount
                                                campaigns to make timely adjustments in many different stores
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        <div className="mb-7 text-center">
                                            <img
                                                src={require("components/assets/media/illustrations/dozzy-1/6.png")}
                                                className="mh-125px mb-4"
                                                alt=""
                                            />
                                            <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">Order</div>
                                            <div className="text-gray-500 fw-semibold fs-4 text-center">
                                                Synchronize - manage and combine alerts for on-time fulfillment
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        <div className="mb-7 text-center">
                                            <img
                                                src={require("components/assets/media/illustrations/dozzy-1/2.png")}
                                                className="mh-125px mb-4"
                                                alt=""
                                            />
                                            <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">Message</div>
                                            <div className="text-gray-500 fw-semibold fs-4 text-center">
                                                Real-time chat to handle complaints and questions from buyers. Combined
                                                with product remarketing campaigns
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        <div className="mb-7 text-center">
                                            <img
                                                src={require("components/assets/media/illustrations/dozzy-1/4.png")}
                                                className="mh-125px mb-4"
                                                alt=""
                                            />
                                            <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">Affiliate</div>
                                            <div className="text-gray-500 fw-semibold fs-4 text-center">
                                                Manage product affiliate marketing streams, quickly find affiliate
                                                marketing creators
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        <div className="mb-7 text-center">
                                            <img
                                                src={require("components/assets/media/illustrations/dozzy-1/8.png")}
                                                className="mh-125px mb-4"
                                                alt=""
                                            />
                                            <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">Store</div>
                                            <div className="text-gray-500 fw-semibold fs-4 text-center">
                                                Synchronize data and manage multiple stores from different countries
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-20">
                        <div className="landing-curve landing-dark-color ">
                            <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div className="pb-15 pt-18 landing-dark-bg">
                            <div className="container">
                                <div
                                    className="text-center mt-15 mb-18"
                                    id="achievements"
                                    data-kt-scroll-offset="{default: 100, lg: 150}"
                                >
                                    <h3 className="fs-2hx text-white fw-bold mb-5">We Make Things Better</h3>
                                    <div className="fs-5 text-gray-700 fw-bold">
                                        Save thousands to millions of bucks by using single tool <br />
                                        for different amazing and great useful admin
                                    </div>
                                </div>
                                <div className="d-flex flex-center">
                                    <div className="d-flex flex-wrap flex-center justify-content-lg-center mb-15 mx-auto w-xl-900px gap-3">
                                        <div
                                            className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                            style={{
                                                backgroundImage: `url("${
                                                    require("components/assets/media/svg/misc/octagon.svg").default
                                                }")`,
                                            }}
                                        >
                                            <i className="ki-outline ki-element-11 fs-2tx text-white mb-3" />{" "}
                                            <div className="mb-0">
                                                <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                                    <div className="min-w-70px counted">
                                                        <CountUp end={15} scrollSpyOnce enableScrollSpy={true} />
                                                        k+
                                                    </div>
                                                </div>
                                                <span className="text-gray-600 fw-semibold fs-5 lh-0">
                                                    Connected Shops{" "}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                            style={{
                                                backgroundImage: `url("${
                                                    require("components/assets/media/svg/misc/octagon.svg").default
                                                }")`,
                                            }}
                                        >
                                            <i className="ki-outline ki-chart-pie-4 fs-2tx text-white mb-3" />{" "}
                                            <div className="mb-0">
                                                <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                                    <div
                                                        className="min-w-70px counted"
                                                        data-kt-countup="true"
                                                        data-kt-countup-value={80}
                                                        data-kt-countup-suffix="K+"
                                                        data-kt-initialized={1}
                                                    >
                                                        <CountUp end={80} scrollSpyOnce enableScrollSpy={true} />
                                                        K+
                                                    </div>
                                                </div>
                                                <span className="text-gray-600 fw-semibold fs-5 lh-0">
                                                    Statistic Reports{" "}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                            style={{
                                                backgroundImage: `url("${
                                                    require("components/assets/media/svg/misc/octagon.svg").default
                                                }")`,
                                            }}
                                        >
                                            <i className="ki-outline ki-basket fs-2tx text-white mb-3" />{" "}
                                            <div className="mb-0">
                                                <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                                    <div
                                                        className="min-w-70px counted"
                                                        data-kt-countup="true"
                                                        data-kt-countup-value={35}
                                                        data-kt-countup-suffix="M+"
                                                        data-kt-initialized={1}
                                                    >
                                                        <CountUp end={35} scrollSpyOnce enableScrollSpy={true} />
                                                        M+
                                                    </div>
                                                </div>
                                                <span className="text-gray-600 fw-semibold fs-5 lh-0">
                                                    Secure Payments{" "}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain"
                                            style={{
                                                backgroundImage: `url("${
                                                    require("components/assets/media/svg/misc/octagon.svg").default
                                                }")`,
                                            }}
                                        >
                                            <i className="ki-outline ki-users fs-2tx text-white mb-3" />{" "}
                                            <div className="mb-0">
                                                <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                                    <div
                                                        className="min-w-70px counted"
                                                        data-kt-countup="true"
                                                        data-kt-countup-value={35}
                                                        data-kt-countup-suffix="M+"
                                                        data-kt-initialized={1}
                                                    >
                                                        <CountUp end={150} scrollSpyOnce enableScrollSpy={true} />+
                                                    </div>
                                                </div>
                                                <span className="text-gray-600 fw-semibold fs-5 lh-0">
                                                    Partners Using{" "}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className=" z-index-2">
                        <div className="container">
                            <h3 className="fs-2hx text-gray-900 mb-5 text-center">About Cotik</h3>
                            <div className="fs-2 fw-semibold text-muted text-center mb-3">
                                <span className="fs-1 lh-1 text-gray-700">“</span>
                                When you care about your topic, you’ll write about it in a <br />
                                <span className="text-gray-700 me-1">more powerful</span>, emotionally expressive way
                                <span className="fs-1 lh-1 text-gray-700">“</span>
                            </div>
                            <div className="fs-2 fw-semibold text-muted text-center">
                                <span className="fs-4 fw-bold text-gray-600">Cotik Team</span>
                            </div>
                        </div>
                    </div>
                    {/* <Packages /> */}

                    <div className="mt-20">
                        <div className="landing-curve landing-dark-color ">
                            <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div className="pb-15 pt-18 landing-dark-bg">
                            <div className="container">
                                <div
                                    className="text-center mt-15 mb-18"
                                    id="achievements"
                                    data-kt-scroll-offset="{default: 100, lg: 150}"
                                >
                                    <h3 className="fs-2hx text-white fw-bold mb-5">Maximize Your Brand's Potential</h3>
                                    <div className="fs-5 text-gray-700 fw-bold">
                                        <p>
                                            Save thousands to millions of bucks by using single tool <br />
                                            for different amazing and great useful admin
                                        </p>
                                        <a className="btn btn-success mt-5" href="/admin">
                                            Try It
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                    </div>

                    <div className="position-relative z-index-2">
                        <div className="container">
                            <div className="text-center mb-17">
                                <h3
                                    className="fs-2hx text-gray-900 mb-5"
                                    id="clients"
                                    data-kt-scroll-offset="{default: 125, lg: 150}"
                                >
                                    About
                                </h3>
                                <div className="fs-5 text-muted fw-bold">
                                    We offer comprehensive services to help transform your brand into a powerful online
                                    format.<br></br> We'll guide you through the setup - linking your stores so you can
                                    run them all in one place and with just a few simple steps.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative py-10 pt-20">
                        <h3
                            className="fs-2hx text-gray-900 mb-20 text-center"
                            id="clients"
                            data-kt-scroll-offset="{default: 125, lg: 150}"
                        >
                            Connected{" "}
                            <span
                                style={{
                                    background:
                                        "linear-gradient(to right, rgb(18, 206, 93) 0%, rgb(255, 216, 12) 100%) text",
                                    WebkitTextFillColor: "transparent",
                                }}
                            >
                                Partner
                            </span>
                        </h3>
                        <div className="logo-slider swiper-smooth-custom">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={2}
                                autoplay={{
                                    delay: 0,
                                }}
                                simulateTouch={false}
                                loop={true}
                                speed={5000}
                                modules={[Autoplay]}
                                breakpoints={{
                                    480: {
                                        slidesPerView: 3,
                                    },
                                    720: {
                                        slidesPerView: 4,
                                    },
                                    1024: {
                                        slidesPerView: 5,
                                    },
                                }}
                            >
                                {[
                                    HmLogo,
                                    A2kLogo,
                                    FlashshipLogo,
                                    GearmentLogo,
                                    LenfulLogo,
                                    MangoLogo,
                                    OnospodLogo,
                                    PrinteeshubLogo,
                                    KvLogo,
                                    LogiLogo,
                                ].map((logo, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            style={{
                                                width: "220px",
                                                height: "220px",
                                                objectFit: "contain",
                                                maxWidth: "100%",
                                            }}
                                            src={logo}
                                            alt={`Brand Logo ${index + 1}`}
                                            className="logo image-disable-drag"
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    <div className="mt-20">
                        <div className="pb-15 pt-18 landing-dark-bg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        <img
                                            src={require("components/assets/media/illustrations/sigma-1/2-dark.png")}
                                            className="me-2"
                                            alt=""
                                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="mt-15 mb-18">
                                            <h3 className="fs-2hx text-white fw-bold mb-10">
                                                Our{" "}
                                                <span
                                                    style={{
                                                        background:
                                                            "linear-gradient(to right, rgb(18, 206, 93) 0%, rgb(255, 216, 12) 100%) text",
                                                        WebkitTextFillColor: "transparent",
                                                    }}
                                                >
                                                    Approach
                                                </span>
                                            </h3>
                                            <div className="row text-white mb-8">
                                                <div className="col-md-2">
                                                    <span style={{ fontSize: "80px", fontWeight: "bolder" }}>1</span>
                                                </div>

                                                <div className="col-md-10">
                                                    <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                                                        Goal Definition
                                                    </p>
                                                    <p style={{ fontSize: "20px", fontWeight: "100" }}>
                                                        We start by understanding your unique business goals.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row text-white mb-8">
                                                <div className="col-md-2">
                                                    <span style={{ fontSize: "80px", fontWeight: "bolder" }}>2</span>
                                                </div>

                                                <div className="col-md-10">
                                                    <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                                                        Linking and usage instructions
                                                    </p>
                                                    <p style={{ fontSize: "20px", fontWeight: "100" }}>
                                                        We send support team to guide throughout the cooperation
                                                        process.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row text-white mb-8">
                                                <div className="col-md-2">
                                                    <span style={{ fontSize: "80px", fontWeight: "bolder" }}>3</span>
                                                </div>

                                                <div className="col-md-10">
                                                    <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                                                        Customize settings to suit your business
                                                    </p>
                                                    <p style={{ fontSize: "20px", fontWeight: "100" }}>
                                                        Our support team will guide you through data sync settings and
                                                        alerts for your business.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row text-white mb-8">
                                                <div className="col-md-2">
                                                    <span style={{ fontSize: "80px", fontWeight: "bolder" }}>4</span>
                                                </div>

                                                <div className="col-md-10">
                                                    <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                                                        Precise Execution & Optimization
                                                    </p>
                                                    <p style={{ fontSize: "20px", fontWeight: "100" }}>
                                                        We constantly update and guide how to optimize usage so that
                                                        businesses can achieve maximum efficiency.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-20 mb-n20 position-relative z-index-2">
                        <div className="container">
                            <div className="text-center">
                                <h3
                                    className="fs-2hx text-gray-900 mb-5"
                                    id="clients"
                                    data-kt-scroll-offset="{default: 125, lg: 150}"
                                >
                                    How will you{" "}
                                    <span
                                        style={{
                                            background:
                                                "linear-gradient(to right, rgb(18, 206, 93) 0%, rgb(255, 216, 12) 100%) text",
                                            WebkitTextFillColor: "transparent",
                                        }}
                                    >
                                        benefit?
                                    </span>
                                </h3>
                                <div className="fs-5 text-muted fw-bold">
                                    Partnering with Cotik for your e-commerce advertising needs comes
                                    <br /> with a multitude of advantages that can transform your business. Here's
                                    <br /> how you'll benefit from our expertise and services:
                                </div>
                                <div className="row g-lg-10 mt-10 mb-10 mb-lg-20">
                                    <div className="col-lg-4">
                                        <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                            <div className="mb-7 text-center">
                                                <img
                                                    src={require("components/assets/media/illustrations/sketchy-1/1.png")}
                                                    className="mh-125px mb-4"
                                                    alt=""
                                                />
                                                <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                    Client insights
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                            <div className="mb-7 text-center">
                                                <img
                                                    src={require("components/assets/media/illustrations/sketchy-1/2.png")}
                                                    className="mh-125px mb-4"
                                                    alt=""
                                                />
                                                <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                    Increased efficiency
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                            <div className="mb-7 text-center">
                                                <img
                                                    src={require("components/assets/media/illustrations/sketchy-1/3.png")}
                                                    className="mh-125px mb-4"
                                                    alt=""
                                                />
                                                <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                    Process automation
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                            <div className="mb-7 text-center">
                                                <img
                                                    src={require("components/assets/media/illustrations/sketchy-1/4.png")}
                                                    className="mh-125px mb-4"
                                                    alt=""
                                                />
                                                <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                    Data-driven innovation
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                            <div className="mb-7 text-center">
                                                <img
                                                    src={require("components/assets/media/illustrations/sketchy-1/5.png")}
                                                    className="mh-125px mb-4"
                                                    alt=""
                                                />
                                                <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                    Reduced costs
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                            <div className="mb-7 text-center">
                                                <img
                                                    src={require("components/assets/media/illustrations/sketchy-1/6.png")}
                                                    className="mh-125px mb-4"
                                                    alt=""
                                                />
                                                <div className="fs-2 fw-bold text-gray-900 mb-3 text-center">
                                                    Market insights
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
                                    style={{ background: "linear-gradient(90deg, #20AA3E 0%, #03A588 100%)" }}
                                >
                                    <div className="my-2 me-5">
                                        <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">
                                            Start With Cotik App Today,{" "}
                                            <span className="fw-normal">Speed Up Development!</span>
                                        </div>
                                        <div
                                            style={{ textAlign: "left" }}
                                            className="fs-6 fs-lg-5 text-white fw-semibold opacity-75"
                                        >
                                            Join over 100,000 Professionals Community to Stay Ahead
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="mb-0">
                        <div className="landing-curve landing-dark-color ">
                            <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div className="landing-dark-bg pt-20">
                            <div className="container">
                                <div className="row py-10 py-lg-20">
                                    <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                                        <div className="rounded landing-dark-border p-9 mb-10">
                                            <h2 className="text-white">Would you need a Custom License?</h2>
                                            <span className="fw-normal fs-4 text-gray-700">
                                                Email us to{" "}
                                                <Link
                                                    to="/support"
                                                    className="text-white opacity-50 text-hover-primary"
                                                >
                                                    support@cotik.app
                                                </Link>
                                            </span>
                                        </div>
                                        <div className="rounded landing-dark-border p-9">
                                            <h2 className="text-white">How About a Custom Project?</h2>
                                            <span className="fw-normal fs-4 text-gray-700">
                                                Use Our Custom Development Service.{" "}
                                                <Link to="/admin" className="text-white opacity-50 text-hover-primary">
                                                    Click to Get a Quote
                                                </Link>
                                            </span>
                                        </div>

                                        <div className="d-flex mt-10">
                                            <div className="d-flex fw-semibold flex-column">
                                                <h4 className="fw-bold text-gray-500 mb-6">Stay Connected</h4>
                                                <Link
                                                    to="https://www.facebook.com/cotikapp"
                                                    className="mb-6"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={
                                                            require("components/assets/media/svg/brand-logos/facebook-4.svg")
                                                                .default
                                                        }
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Facebook
                                                    </span>
                                                </Link>
                                                <Link
                                                    to="https://www.youtube.com/@cotikapp"
                                                    className="mb-6"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={
                                                            require("components/assets/media/svg/brand-logos/youtube-play.svg")
                                                                .default
                                                        }
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Youtube
                                                    </span>
                                                </Link>
                                                <Link
                                                    to="https://t.me/cotikcommunity"
                                                    className="mb-6"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={
                                                            require("components/assets/media/svg/brand-logos/telegram.svg")
                                                                .default
                                                        }
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Telegram
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <iframe
                                            title="map"
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3055.5874483915077!2d-105.28228482419344!3d40.017661871506526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bec26470fb14d%3A0x11c96ab6d2fd81db!2sBoulder%20Mediation!5e0!3m2!1svi!2s!4v1737037416769!5m2!1svi!2s"
                                            style={{ border: 0, width: "100%", aspectRatio: "2 / 1.5" }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="landing-dark-separator"></div>
                            <div className="container">
                                <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center order-2 order-md-1">
                                            <Link to="/">
                                                <img
                                                    alt="Logo"
                                                    src={require("components/assets/media/custom/logo.png")}
                                                    className="h-15px h-md-20px"
                                                />
                                            </Link>
                                            <span className="mx-5 fs-6 fw-semibold text-gray-600 pt-1" to="/">
                                                © 2024 Cotik App.
                                            </span>
                                        </div>
                                        <p className="text-gray-600">
                                            1942 Broadway #314c, Boulder, CO 80302, United States
                                        </p>
                                    </div>
                                    <ul className="menu menu-gray-600 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                                        <li className="menu-item">
                                            <Link to="/about" className="menu-link px-2">
                                                About
                                            </Link>
                                        </li>
                                        <li className="menu-item mx-5">
                                            <Link
                                                to="https://t.me/cotikcommunity"
                                                className="menu-link px-2"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Support
                                            </Link>
                                        </li>
                                        <li className="menu-item">
                                            <Link to="/admin/packages" className="menu-link px-2">
                                                Purchase
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
