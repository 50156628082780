import { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { getListOrders, saveEditOrder, sendToPrinter, cancelToPrinter, saveEditOrderMember, exportOrder } from "actions/orderActions";
import { getShops } from "actions/shopActions";
import { getStaffs } from "actions/teamActions";
import { formatterCreateAt, subStringText, FormatterStatus, formatterImage, convertTZ, getRange2TZ, getTimezoneOffset } from "components/Function";
import { ReactTable } from "components/Table";
import { textFilter } from 'react-bootstrap-table2-filter';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import ReactImageMagnify from 'react-image-magnify';
import { Modaleditdesign, Modaleditorderstatus, Modalbuylabel, ModalUpdateMember, Modalimporttracking } from "./modal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { CSVLink } from "react-csv";
import cx from "classnames";

var nameFilter_printer;
var nameFilter_shop_code;
var nameFilter_status;
var nameFilter_work_status;
var nameFilter_staff;

function Listorders(props) {
    const [isOpenModalEditDesign, setIsOpenModalEditDesign] = useState(false);
    const [isOpenModalEditOrderStatus, setIsOpenModalEditOrderStatus] = useState(false);
    const [isOpenModalBuyLabel, setIsOpenModalBuyLabel] = useState(false);
    const [isOpenModalImportTracking, setIsOpenModalImportTracking] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [editdesign, setEditDesign] = useState([]);
    const [editorderstatus, setEditOrderStatus] = useState([]);
    const [buylabel, setBuyLabel] = useState([]);
    const [printer_tmp, setPrinterTmp] = useState({});
    const [orders_selected, setOrdersSelected] = useState([]);
    const [editMember, setEditMember] = useState(null);
    const [date_type, setDateType] = useState("");
    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");
    const [filter_printer, setFilterPrinter] = useState("");
    const [filter_shop_code, setFilterShopCode] = useState("");
    const [filter_status, setFilterStatus] = useState("");
    const [filter_work_status, setFilterWorkStatus] = useState("");
    const [filter_staff, setFilterStaff] = useState("");
    const [export_orders_data, setExportOrdersData] = useState([]);
    const [initiateDownload, setInitiateDownload] = useState(false);
    const [tab, setTab] = useState("orders_all");
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tab_order = searchParams.get("tab");
    const navigate = useNavigate();
    const btnRef = useRef(null);
    const { t } = useTranslation();
    var { listorders, totalsize, isLoading, all_orders_for_export } = props.order;
    var { list } = props.shop;
    var { list: list_team } = props.team;
    var { getListOrders, subStringText, formatterCreateAt, saveEditOrder, sendToPrinter, cancelToPrinter, FormatterStatus, formatterImage, saveEditOrderMember, getShops, getStaffs, exportOrder } = props;
    const list_shop = list?.map((v, i) => {
        return { label: `${v?.name} (${v?.note}) - ${v?.region} - ${v?.code}`, value: v?._id };
    });
    const list_staff = list_team?.map((v, i) => {
        return { label: v?.user?.email, value: v?.user?._id };
    });
    const list_date_type = [
        { "label": "Alltime", "value": "" },
        { "label": "Today", "value": "today" },
        { "label": "Yesterday", "value": "yesterday" },
        { "label": "Last week", "value": "last_week" },
        { "label": "Last month", "value": "last_month" },
        { "label": "Last year", "value": "last_year" },
        { "label": "This week", "value": "this_week" },
        { "label": "This month", "value": "this_month" },
        { "label": "This year", "value": "this_year" },
        { "label": "Custom", "value": "custom" }
    ];

    const list_status = [
        { "label": `UNPAID`, "value": `UNPAID` },
        { "label": `ON_HOLD`, "value": `ON_HOLD` },
        { "label": `PARTIALLY_SHIPPING`, "value": `PARTIALLY_SHIPPING` },
        { "label": `AWAITING_SHIPMENT`, "value": `AWAITING_SHIPMENT` },
        { "label": `AWAITING_COLLECTION`, "value": `AWAITING_COLLECTION` },
        { "label": `IN_TRANSIT`, "value": `IN_TRANSIT` },
        { "label": `DELIVERED`, "value": `DELIVERED` },
        { "label": `COMPLETED`, "value": `COMPLETED` },
    ];
    const list_work_status = [
        { "label": `new`, "value": `new` },
        { "label": `processing`, "value": `processing` },
        { "label": `working`, "value": `working` },
        { "label": `worked`, "value": `worked` },
        { "label": `overdue`, "value": `overdue` },
        { "label": `ship_late`, "value": `ship_late` },
    ];
    const list_printer = [
        { "label": `Flashship`, "value": `flashship` },
        { "label": `Mangoteeprints`, "value": `mangoteeprints` },
        { "label": `Gearment`, "value": `gearment` },
        { "label": `HM Fulfill`, "value": `hmfulfill` },
        { "label": `Lenful`, "value": `lenful` },
        { "label": `OnosPOD`, "value": `onospod` },
        { "label": `A2K Ecom`, "value": `a2kecom` },
        { "label": `Printeeshub`, "value": `printeeshub` },
        // { "label": `Monkeykingprint`, "value": `monkeykingprint` },
        // { "label": `Other`, "value": `other` },
    ];
    const list_manufacturer = [
        { "label": `Gildan`, "value": `gildan`, "enabled": ["flashship", "hmfulfill", "lenful", "printeeshub"] },
        { "label": `Bella canvas`, "value": `bellacanvas`, "enabled": ["flashship", "hmfulfill", "lenful"] },
        { "label": `Flash POD`, "value": `flashpod`, "enabled": ["flashship"] },
        { "label": `Comfort`, "value": `comfort`, "enabled": ["flashship", "hmfulfill", "lenful"] },
        { "label": `Tote bag`, "value": `totebag`, "enabled": ["flashship", "hmfulfill", "lenful"] },

        { "label": `Gildan Fast US`, "value": `gildanfastus`, "enabled": ["mangoteeprints"] },
        { "label": `Bella canvas Fast US`, "value": `bellacanvasfastus`, "enabled": ["mangoteeprints"] },
        { "label": `Comfort Fast US`, "value": `comfortfastus`, "enabled": ["mangoteeprints"] },
        { "label": `Tote bag Fast US`, "value": `totebagfastus`, "enabled": ["mangoteeprints"] },

        { "label": `Gildan Tiktok`, "value": `gildantiktok`, "enabled": ["mangoteeprints", "gearment", "a2kecom"] },
        { "label": `Bella canvas Tiktok`, "value": `bellacanvastiktok`, "enabled": ["mangoteeprints", "gearment", "a2kecom"] },
        { "label": `Comfort Tiktok`, "value": `comforttiktok`, "enabled": ["mangoteeprints", "gearment", "a2kecom"] },

        { "label": `Gildan US1`, "value": `gildanus1`, "enabled": ["mangoteeprints"] },
        { "label": `Bella canvas US1`, "value": `bellacanvasus1`, "enabled": ["mangoteeprints"] },
        { "label": `Comfort US1`, "value": `comfortus1`, "enabled": ["mangoteeprints"] },

        { "label": `All Over Print`, "value": `alloverprint`, "enabled": ["onospod"] },
    ];
    const list_type = [
        { "label": `T-Shirt G5000`, "value": `tshirt_g5000`, "enabled": ["gildan"] },
        { "label": `Sweatshirt G18000`, "value": `sweatshirt_g18000`, "enabled": ["gildan"] },
        { "label": `Hoodie G18500`, "value": `hoodie_g18500`, "enabled": ["gildan"] },
        { "label": `Tank top G2200`, "value": `tanktop_g2200`, "enabled": ["gildan"] },
        { "label": `T-Shirt B3001`, "value": `tshirt_b3001`, "enabled": ["bellacanvas"] },
        { "label": `T-Shirt FLPOD`, "value": `tshirt_flpod`, "enabled": ["flashpod"] },
        { "label": `T-Shirt C1717`, "value": `tshirt_c1717`, "enabled": ["comfort"] },
        { "label": `Tote bag`, "value": `totebag`, "enabled": ["totebag"] },

        { "label": `T-Shirt 5000`, "value": `tshirt_5000_fastus`, "enabled": ["gildanfastus"] },
        { "label": `Sweatshirt 18000`, "value": `sweatshirt_18000_fastus`, "enabled": ["gildanfastus"] },
        { "label": `Hoodie 18500`, "value": `hoodie_18500_fastus`, "enabled": ["gildanfastus"] },
        { "label": `Tank top 2200`, "value": `tanktop_2200`, "enabled": ["gildanfastus"] },

        { "label": `T-Shirt 5000`, "value": `tshirt_5000_tiktok`, "enabled": ["gildantiktok"] },
        { "label": `Sweatshirt 18000`, "value": `sweatshirt_18000_tiktok`, "enabled": ["gildantiktok"] },
        { "label": `Hoodie 18500`, "value": `hoodie_18500_tiktok`, "enabled": ["gildantiktok"] },

        { "label": `T-Shirt 5000`, "value": `tshirt_5000_us1`, "enabled": ["gildanus1"] },
        { "label": `Sweatshirt 18000`, "value": `sweatshirt_18000_us1`, "enabled": ["gildanus1"] },
        { "label": `Hoodie 18500`, "value": `hoodie_18500_us1`, "enabled": ["gildanus1"] },

        { "label": `T-Shirt 3001`, "value": `tshirt_3001`, "enabled": ["bellacanvasfastus", "bellacanvastiktok", "bellacanvasus1"] },
        { "label": `T-Shirt 1717`, "value": `tshirt_1717`, "enabled": ["comfortfastus", "comforttiktok", "comfortus1"] },
        { "label": `Tote bag`, "value": `totebag`, "enabled": ["totebagfastus"] },

        { "label": `Premium 3D T-Shirt`, "value": `premium3dtshirt`, "enabled": ["alloverprint"] },
        { "label": `Sweatshirt Unisex`, "value": `sweatshirtunisex`, "enabled": ["alloverprint"] },
        { "label": `Hoodie`, "value": `hoodie`, "enabled": ["alloverprint"] },
        { "label": `Tank Top Men`, "value": `tanktopmen`, "enabled": ["alloverprint"] },
        { "label": `Tank Top Women`, "value": `tanktopwomen`, "enabled": ["alloverprint"] },
        { "label": `Premium Polo Shirt Men`, "value": `premiumpoloshirtmen`, "enabled": ["alloverprint"] },
        { "label": `Premium Polo Shirt Women`, "value": `premiumpoloshirtwomen`, "enabled": ["alloverprint"] },
    ];
    const list_size = [
        { "label": `S`, "value": `s`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `M`, "value": `m`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `L`, "value": `l`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `XL`, "value": `xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `2XL`, "value": `2xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `3XL`, "value": `3xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `4XL`, "value": `4xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_b3001", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `5XL`, "value": `5xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_b3001", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tshirt_3001", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
    ];
    const list_color = [
        { "label": `Black`, "value": `black`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "totebag", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717"] },
        { "label": `White`, "value": `white`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717"] },
        { "label": `Sportgrey`, "value": `sportgrey`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200"] },
        { "label": `Sand`, "value": `sand`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok"] },
        { "label": `Forestgreen`, "value": `forestgreen`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok"] },
        { "label": `Lightpink`, "value": `lightpink`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok"] },
        { "label": `Navy`, "value": `navy`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "sweatshirt_18000_tiktok"] },
        { "label": `Militarygreen`, "value": `militarygreen`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok"] },
        { "label": `Maroon`, "value": `maroon`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok"] },
        { "label": `Red`, "value": `red`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok"] },
        { "label": `Royalblue`, "value": `royalblue`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "sweatshirt_18000_fastus"] },
        { "label": `Royal`, "value": `royal`, "enabled": ["tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_tiktok"] },
        { "label": `Ashgrey`, "value": `ashgrey`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "sweatshirt_18000_fastus"] },
        { "label": `Ash`, "value": `ash`, "enabled": ["tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_tiktok"] },
        { "label": `Lightblue`, "value": `lightblue`, "enabled": ["tshirt_g5000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_tiktok"] },
        { "label": `Charcoal`, "value": `charcoal`, "enabled": ["tshirt_g5000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "tshirt_5000_us1"] },
        { "label": `Darkheather`, "value": `darkheather`, "enabled": ["tshirt_g5000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "tshirt_5000_us1"] },
        { "label": `Darkgreyheather`, "value": `darkgreyheather`, "enabled": ["tshirt_b3001", "tshirt_3001"] },
        { "label": `Purple`, "value": `purple`, "enabled": ["tshirt_g5000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_tiktok"] },
        { "label": `Orange`, "value": `orange`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok"] },
        { "label": `Natural`, "value": `natural`, "enabled": ["tshirt_b3001", "totebag", "tshirt_3001"] },
        { "label": `Pepper`, "value": `pepper`, "enabled": ["tshirt_c1717", "tshirt_1717"] },
        { "label": `Ivory`, "value": `ivory`, "enabled": ["tshirt_c1717", "tshirt_1717"] },
        { "label": `Moss`, "value": `moss`, "enabled": ["tshirt_1717"] },
        { "label": `Bluejean`, "value": `bluejean`, "enabled": ["tshirt_1717"] },
        { "label": `Crunchberry`, "value": `crunchberry`, "enabled": ["tshirt_1717"] },
        { "label": `Yam`, "value": `yam`, "enabled": ["tshirt_1717"] },
        { "label": `As Design`, "value": `asdesign`, "enabled": ["premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `Same Design`, "value": `samedesign`, "enabled": ["premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
    ];
    const list_shipping_method = [
        { "label": `FirstClass`, "value": `1`, "enabled": ["flashship"] },
        { "label": `Priority`, "value": `2`, "enabled": ["flashship"] },
        { "label": `RushProduction`, "value": `3`, "enabled": ["flashship"] },
        { "label": `Expedite`, "value": `6`, "enabled": ["flashship"] },

        { "label": `Standard`, "value": `standard`, "enabled": ["mangoteeprints"] },
        { "label": `Priority`, "value": `priority`, "enabled": ["mangoteeprints"] },
        { "label": `Express`, "value": `express`, "enabled": ["mangoteeprints"] },
        { "label": `Rush`, "value": `rush`, "enabled": ["mangoteeprints"] },
        { "label": `Expedite`, "value": `expedite`, "enabled": ["mangoteeprints"] },

        { "label": `Standard`, "value": `0`, "enabled": ["gearment"] },
        { "label": `Fastship 2days`, "value": `1`, "enabled": ["gearment"] },
        { "label": `Ground`, "value": `2`, "enabled": ["gearment"] },
        { "label": `Stamp`, "value": `3`, "enabled": ["gearment"] },

        { "label": `Standard`, "value": `1`, "enabled": ["hmfulfill",] },
        { "label": `Priority`, "value": `3`, "enabled": ["hmfulfill"] },

        // { "label": `Standard`, "value": `0`, "enabled": ["lenful"] },
        // { "label": `Ground`, "value": `1`, "enabled": ["lenful"] },
        // { "label": `Express`, "value": `2`, "enabled": ["lenful"] },
        // { "label": `Special`, "value": `4`, "enabled": ["lenful"] },
        // { "label": `Us Island`, "value": `5`, "enabled": ["lenful"] },
        // { "label": `WW Standard`, "value": `6`, "enabled": ["lenful"] },
        { "label": `Shipping By Platform`, "value": `7`, "enabled": ["lenful"] },
        // { "label": `Shipping By Seller`, "value": `8`, "enabled": ["lenful"] },

        { "label": `Shipping By Tiktok`, "value": `sbtt`, "enabled": ["onospod"] },
        // { "label": `Onos Express`, "value": `onosexpress`, "enabled": ["onospod"] },
        // { "label": `COD`, "value": `cod`, "enabled": ["onospod"] },

        { "label": `Standard Ship`, "value": `standard_ship`, "enabled": ["a2kecom"] },
        { "label": `Ground Ship`, "value": `ground_ship`, "enabled": ["a2kecom"] },
        { "label": `Fast Ship`, "value": `fast_ship`, "enabled": ["a2kecom"] },

        { "label": `Standard`, "value": `standard`, "enabled": ["printeeshub"] },
        { "label": `Rush Product`, "value": `rush_product`, "enabled": ["printeeshub"] },
        { "label": `Expedite Tiktok`, "value": `expedite_tiktok`, "enabled": ["printeeshub"] },
    ];

    const list_printing_area = [
        { "label": `One side`, "value": `oneside`, "enabled": ["lenful"] },
        { "label": `Both sides`, "value": `bothsides`, "enabled": ["lenful"] },
    ];

    useEffect(() => {
        var dateStart_tmp = dateStart;
        var dateEnd_tmp = dateEnd;
        if (date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }
        getListOrders(page, sizePerPage, searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, dateStart_tmp, dateEnd_tmp);
    }, [getListOrders, page, sizePerPage, searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, dateStart, dateEnd, date_type]);

    useEffect(() => {
        if (window.location.pathname.indexOf("/orders") > -1 && tab_order) {
            navigate('/admin/orders');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPage(1);
        setSizePerPage(10);

        if (date_type === "") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);
            setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        }
        if (date_type === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);
            setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        }
        if (date_type === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);
            setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        }
        if (date_type === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);
            setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        }
        if (date_type === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);
            setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        }
        if (date_type === "this_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);
            setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        }
        if (date_type === "last_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);
            setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        }
        if (date_type === "this_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);
            setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        }
        if (date_type === "last_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);
            setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        }
    }, [date_type]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        var printer = {};
        for (var i = 0; i < listorders.length; i++) {
            if (listorders[i].printer) {
                printer = {
                    ...printer,
                    [listorders[i]._id]: {
                        _id: listorders[i]._id,
                        printer: {
                            ...listorders[i].printer
                        },
                        page,
                        sizePerPage,
                        searchText,
                        filter_printer,
                        filter_shop_code,
                        filter_status,
                        filter_work_status,
                        filter_staff,
                        dateStart,
                        dateEnd,
                    }
                }
            }
        }
        setPrinterTmp(printer);
    }, [listorders]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getShops({ page: 1, sizeperpage: 9999 });
    }, [getShops]);

    useEffect(() => {
        getStaffs({ page: 1, sizeperpage: 9999 });
    }, [getStaffs]);

    useEffect(() => {
        if (all_orders_for_export.length > 0) {
            var export_orders_data = [];
            for (var i = 0; i < all_orders_for_export.length; i++) {
                if (orders_selected.length > 0) {
                    if (!orders_selected.includes(all_orders_for_export[i]._id)) {
                        continue;
                    }
                }
                for (var j = 0; j < all_orders_for_export[i]?.line_items.length; j++) {
                    var export_orders_data_tmp = {
                        id: j === 0 ? "'" + all_orders_for_export[i]?.apiOrderId : "",
                        status: j === 0 ? all_orders_for_export[i]?.status : "",
                        title: all_orders_for_export[i]?.line_items[j]?.product_name,
                        image: all_orders_for_export[i]?.line_items[j]?.sku_image,
                        skus: all_orders_for_export[i]?.line_items[j]?.sku_name,
                        seller_sku: all_orders_for_export[i]?.line_items[j]?.seller_sku,
                        label: j === 0 ? all_orders_for_export[i]?.shipping_label : "",
                        tracking_number: j === 0 ? "'" + all_orders_for_export[i]?.tracking_number : "",
                        price: j === 0 ? all_orders_for_export[i]?.payment.total_amount : "",
                        est: j === 0 ? all_orders_for_export[i]?.shipping_type === "TIKTOK" ? (((+all_orders_for_export[i]?.payment?.original_total_product_price - +all_orders_for_export[i]?.payment?.seller_discount) - 6 / 100 * (+all_orders_for_export[i]?.payment?.total_amount + +all_orders_for_export[i]?.payment?.platform_discount - +all_orders_for_export[i]?.payment?.tax)).toFixed(2)) : ((+all_orders_for_export[i]?.payment?.original_total_product_price - +all_orders_for_export[i]?.payment?.seller_discount + +all_orders_for_export[i]?.payment?.shipping_fee) - 6 / 100 * (+all_orders_for_export[i]?.payment?.total_amount + +all_orders_for_export[i]?.payment?.platform_discount - +all_orders_for_export[i]?.payment?.tax)).toFixed(2) : "",
                        name: j === 0 ? all_orders_for_export[i]?.recipient_address.name : "",
                        address: j === 0 ? all_orders_for_export[i]?.recipient_address?.address_detail + "," + (all_orders_for_export[i].recipient_address?.address_line2 ? all_orders_for_export[i].recipient_address?.address_line2 + ", " : "") + (all_orders_for_export[i].recipient_address?.district_info?.[3]?.address_name ? all_orders_for_export[i].recipient_address?.district_info?.[3]?.address_name + ", " : "") + (all_orders_for_export[i].recipient_address?.district_info?.[2]?.address_name ? all_orders_for_export[i].recipient_address?.district_info?.[2]?.address_name + ", " : "") + (all_orders_for_export[i].recipient_address?.district_info?.[1]?.address_name ? all_orders_for_export[i].recipient_address?.district_info?.[1]?.address_name + ", " : "") + (all_orders_for_export[i].recipient_address?.district_info?.[0]?.address_name ? all_orders_for_export[i].recipient_address?.district_info?.[0]?.address_name + ", " : "") + (all_orders_for_export[i].recipient_address?.postal_code ? all_orders_for_export[i].recipient_address?.postal_code : "") : "",
                        phone: j === 0 ? all_orders_for_export[i]?.recipient_address.phone_number : "",
                        buyer_note: j === 0 ? all_orders_for_export[i]?.buyer_message : "",
                        shop_name: j === 0 ? all_orders_for_export[i]?.shops?.name : "",
                        shop_note: j === 0 ? all_orders_for_export[i]?.shops?.note : "",
                        email_member: j === 0 ? list_staff?.find((v, i) => v?.value === all_orders_for_export[i]?.createdBy)?.label : "",
                        create_time: j === 0 ? formatterCreateAt(all_orders_for_export[i]?.create_time * 1000, "full", "Asia/Ho_Chi_Minh") : "",
                    }
                    export_orders_data.push(export_orders_data_tmp);
                }
            }
            setExportOrdersData(export_orders_data);
            setInitiateDownload(true);
        }
    }, [all_orders_for_export, formatterCreateAt]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    const onChange = (e, row = [], formatExtraData, line_item = [], name = "") => {
        var dateStart_tmp = formatExtraData.dateStart;
        var dateEnd_tmp = formatExtraData.dateEnd;
        if (formatExtraData.date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }

        var printer = {
            [line_item.id]: {
                ...row?.printer?.[line_item.id],
                ...printer_tmp?.[row._id]?.printer?.[line_item.id],
                [name]: e?.value?.toLowerCase() || e?.target?.value || ""
            }
        };
        setPrinterTmp((prev) => ({
            ...prev,
            [row._id]: {
                _id: row._id,
                printer: {
                    ...prev?.[row._id]?.printer,
                    ...printer
                },
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
                filter_printer: formatExtraData.filter_printer,
                filter_shop_code: formatExtraData.filter_shop_code,
                filter_status: formatExtraData.filter_status,
                filter_work_status: formatExtraData.filter_work_status,
                filter_staff: formatExtraData.filter_staff,
                dateStart: dateStart_tmp,
                dateEnd: dateEnd_tmp,
            }
        }));
    }

    const onChangeType = (e, row = [], formatExtraData, line_item = []) => {
        var dateStart_tmp = formatExtraData.dateStart;
        var dateEnd_tmp = formatExtraData.dateEnd;
        if (formatExtraData.date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }

        var printer = {
            [line_item.id]: {
                ...row?.printer?.[line_item.id],
                ...printer_tmp?.[row._id]?.printer?.[line_item.id],
                type: e?.value || "",
                shipping_method: "",
                color: "",
                size: "",
                base_cost: 0,
            }
        };
        setPrinterTmp((prev) => ({
            ...prev,
            [row._id]: {
                _id: row._id,
                printer: {
                    ...prev?.[row._id]?.printer,
                    ...printer
                },
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
                filter_printer: formatExtraData.filter_printer,
                filter_shop_code: formatExtraData.filter_shop_code,
                filter_status: formatExtraData.filter_status,
                filter_work_status: formatExtraData.filter_work_status,
                filter_staff: formatExtraData.filter_staff,
                dateStart: dateStart_tmp,
                dateEnd: dateEnd_tmp,
            }
        }));
    }

    const onChangeManuFacturer = (e, row = [], formatExtraData, line_item = []) => {
        var dateStart_tmp = formatExtraData.dateStart;
        var dateEnd_tmp = formatExtraData.dateEnd;
        if (formatExtraData.date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }

        var printer = {
            [line_item.id]: {
                ...row?.printer?.[line_item.id],
                ...printer_tmp?.[row._id]?.printer?.[line_item.id],
                manufacturer: e?.value || "",
                type: "",
                shipping_method: "",
                color: "",
                size: "",
                base_cost: 0,
                printing_area: "",
            }
        };
        setPrinterTmp((prev) => ({
            ...prev,
            [row._id]: {
                _id: row._id,
                printer: {
                    ...prev?.[row._id]?.printer,
                    ...printer
                },
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
                filter_printer: formatExtraData.filter_printer,
                filter_shop_code: formatExtraData.filter_shop_code,
                filter_status: formatExtraData.filter_status,
                filter_work_status: formatExtraData.filter_work_status,
                filter_staff: formatExtraData.filter_staff,
                dateStart: dateStart_tmp,
                dateEnd: dateEnd_tmp,
            }
        }));
    }

    const onChangePrinter = (e, row = [], formatExtraData, line_item = []) => {
        var dateStart_tmp = formatExtraData.dateStart;
        var dateEnd_tmp = formatExtraData.dateEnd;
        if (formatExtraData.date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }

        var printer = {
            [line_item.id]: {
                ...row?.printer?.[line_item.id],
                ...printer_tmp?.[row._id]?.printer?.[line_item.id],
                printer: e?.value || "",
                manufacturer: "",
                type: "",
                shipping_method: "",
                color: "",
                size: "",
                base_cost: 0,
                printing_area: 0,
            }
        };
        setPrinterTmp((prev) => ({
            ...prev,
            [row._id]: {
                _id: row._id,
                printer: {
                    ...prev?.[row._id]?.printer,
                    ...printer
                },
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
                filter_printer: formatExtraData.filter_printer,
                filter_shop_code: formatExtraData.filter_shop_code,
                filter_status: formatExtraData.filter_status,
                filter_work_status: formatExtraData.filter_work_status,
                filter_staff: formatExtraData.filter_staff,
                dateStart: dateStart_tmp,
                dateEnd: dateEnd_tmp,
            }
        }));
    }

    const onChangeFilterPrinter = (e) => {
        setFilterPrinter(e?.value || "");
        nameFilter_printer(e?.value || "");
    }

    const onChangeFilterShopCode = (e) => {
        setFilterShopCode(e?.value || "");
        nameFilter_shop_code(e?.value || "");
    }

    const onChangeFilterStatus = (e) => {
        setFilterStatus(e?.value || "");
        nameFilter_status(e?.value || "");
    }

    const onChangeFilterWorkStatus = (e) => {
        setFilterWorkStatus(e?.value || "");
        nameFilter_work_status(e?.value || "");
    }

    const onChangeFilterStaff = (e) => {
        setFilterStaff(e?.value || "");
        nameFilter_staff(e?.value || "");
    }

    const onChangeDateType = (e) => {
        setDateType(e?.value || "");
    }

    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(10);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);

        setDateStart(date_start.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
        setDateEnd(date_end.getTime() + getRange2TZ(getTimezoneOffset(new Date(), "Asia/Ho_Chi_Minh")) * 60 * 60 * 1000);
    };

    const handleBeforeSaveCell = (oldValue, newValue, row, column) => {
        var dateStart_tmp = dateStart;
        var dateEnd_tmp = dateEnd;
        if (date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }
        saveEditOrder({ _id: row._id, [column.dataField]: newValue, page, sizePerPage, searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, dateStart: dateStart_tmp, dateEnd: dateEnd_tmp });
    }

    const onClickOpenModalEditDesign = (row = [], formatExtraData, line_item) => {
        if (typeof formatExtraData !== "undefined") {
            var dateStart_tmp = formatExtraData.dateStart;
            var dateEnd_tmp = formatExtraData.dateEnd;
            if (formatExtraData.date_type === "") {
                dateStart_tmp = "";
                dateEnd_tmp = "";
            }

            row = {
                ...row,
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
                filter_printer: formatExtraData.filter_printer,
                filter_shop_code: formatExtraData.filter_shop_code,
                filter_status: formatExtraData.filter_status,
                filter_work_status: formatExtraData.filter_work_status,
                filter_staff: formatExtraData.filter_staff,
                dateStart: dateStart_tmp,
                dateEnd: dateEnd_tmp,
                line_item
            }
        }
        setIsOpenModalEditDesign(!isOpenModalEditDesign);
        setEditDesign(row);
    };

    const onClickOpenModalEditOrderStatus = (row = [], formatExtraData) => {
        if (typeof formatExtraData !== "undefined") {
            var dateStart_tmp = formatExtraData.dateStart;
            var dateEnd_tmp = formatExtraData.dateEnd;
            if (formatExtraData.date_type === "") {
                dateStart_tmp = "";
                dateEnd_tmp = "";
            }

            row = {
                ...row,
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
                filter_printer: formatExtraData.filter_printer,
                filter_shop_code: formatExtraData.filter_shop_code,
                filter_status: formatExtraData.filter_status,
                filter_work_status: formatExtraData.filter_work_status,
                filter_staff: formatExtraData.filter_staff,
                dateStart: dateStart_tmp,
                dateEnd: dateEnd_tmp,
            }
        }
        setIsOpenModalEditOrderStatus(!isOpenModalEditOrderStatus);
        setEditOrderStatus(row);
    };

    const onClickOpenModalBuyLabel = (row = [], formatExtraData) => {
        if (typeof formatExtraData !== "undefined") {
            var dateStart_tmp = formatExtraData.dateStart;
            var dateEnd_tmp = formatExtraData.dateEnd;
            if (formatExtraData.date_type === "") {
                dateStart_tmp = "";
                dateEnd_tmp = "";
            }

            row = {
                ...row,
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
                filter_printer: formatExtraData.filter_printer,
                filter_shop_code: formatExtraData.filter_shop_code,
                filter_status: formatExtraData.filter_status,
                filter_work_status: formatExtraData.filter_work_status,
                filter_staff: formatExtraData.filter_staff,
                dateStart: dateStart_tmp,
                dateEnd: dateEnd_tmp,
            }
        }
        setIsOpenModalBuyLabel(!isOpenModalBuyLabel);
        setBuyLabel(row);
    };

    const onClickSavePrintPartner = (row = [], formatExtraData) => {
        var dateStart_tmp = formatExtraData.dateStart;
        var dateEnd_tmp = formatExtraData.dateEnd;
        if (formatExtraData.date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }
        formatExtraData.dateStart = dateStart_tmp;
        formatExtraData.dateEnd = dateEnd_tmp;
        formatExtraData.printer_tmp[row._id].dateStart = dateStart_tmp;
        formatExtraData.printer_tmp[row._id].dateEnd = dateEnd_tmp;
        saveEditOrder(formatExtraData.printer_tmp[row._id]);
    }

    const onClickSendToPrinterOrdersSelected = () => {
        var dateStart_tmp = dateStart;
        var dateEnd_tmp = dateEnd;
        if (date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }
        var orders_selected_tmp = orders_selected.filter((v, i) => !listorders.some((v2, i2) => v2._id === v && v2.order_status === "worked"));
        sendToPrinter({ _id: orders_selected_tmp.join(), page, sizePerPage, searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, dateStart: dateStart_tmp, dateEnd: dateEnd_tmp, setOrdersSelected });
    }

    const onClickCancelToPrinterOrdersSelected = () => {
        var dateStart_tmp = dateStart;
        var dateEnd_tmp = dateEnd;
        if (date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }
        var orders_selected_tmp = orders_selected.filter((v, i) => !listorders.some((v2, i2) => v2._id === v && v2.order_status === "worked"));
        cancelToPrinter({ _id: orders_selected_tmp.join(), page, sizePerPage, searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, dateStart: dateStart_tmp, dateEnd: dateEnd_tmp });
    }

    const onClickOpenModalBuyLabelOrdersSelected = () => {
        var dateStart_tmp = dateStart;
        var dateEnd_tmp = dateEnd;
        if (date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }
        setIsOpenModalBuyLabel(!isOpenModalBuyLabel);
        var orders_selected_tmp = orders_selected.filter((v, i) => !listorders.some((v2, i2) => v2._id === v && v2.order_status === "worked"));
        setBuyLabel({ _id: orders_selected_tmp.join(), page, sizePerPage, searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, dateStart: dateStart_tmp, dateEnd: dateEnd_tmp, setOrdersSelected });
    }

    const onClickSendToPrinter = (row = [], formatExtraData) => {
        if (typeof formatExtraData !== "undefined") {
            var dateStart_tmp = formatExtraData.dateStart;
            var dateEnd_tmp = formatExtraData.dateEnd;
            if (formatExtraData.date_type === "") {
                dateStart_tmp = "";
                dateEnd_tmp = "";
            }

            row = {
                ...row,
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
                filter_printer: formatExtraData.filter_printer,
                filter_shop_code: formatExtraData.filter_shop_code,
                filter_status: formatExtraData.filter_status,
                filter_work_status: formatExtraData.filter_work_status,
                filter_staff: formatExtraData.filter_staff,
                dateStart: dateStart_tmp,
                dateEnd: dateEnd_tmp,
                setOrdersSelected
            }
        }
        sendToPrinter(row);
    }

    const onClickCancelToPrinter = (row = [], formatExtraData) => {
        if (typeof formatExtraData !== "undefined") {
            var dateStart_tmp = formatExtraData.dateStart;
            var dateEnd_tmp = formatExtraData.dateEnd;
            if (formatExtraData.date_type === "") {
                dateStart_tmp = "";
                dateEnd_tmp = "";
            }

            row = {
                ...row,
                page: formatExtraData.page,
                sizePerPage: formatExtraData.sizePerPage,
                searchText: formatExtraData.searchText,
                filter_printer: formatExtraData.filter_printer,
                filter_shop_code: formatExtraData.filter_shop_code,
                filter_status: formatExtraData.filter_status,
                filter_work_status: formatExtraData.filter_work_status,
                filter_staff: formatExtraData.filter_staff,
                dateStart: dateStart_tmp,
                dateEnd: dateEnd_tmp,
            }
        }
        cancelToPrinter(row);
    }

    const onClickExportOrders = () => {
        var dateStart_tmp = dateStart;
        var dateEnd_tmp = dateEnd;
        if (date_type === "") {
            dateStart_tmp = "";
            dateEnd_tmp = "";
        }
        exportOrder(searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, dateStart_tmp, dateEnd_tmp);
    }

    const onClickOpenModalImportTracking = () => {
        setIsOpenModalImportTracking(!isOpenModalImportTracking);
    }

    const onClick = (data) => {
        setTab(data);
        if (data === "orders_all") {
            setFilterStatus("");
            nameFilter_status("");
        }
        if (data === "orders_on_hold") {
            setFilterStatus("ON_HOLD");
            nameFilter_status("ON_HOLD");
        }
        if (data === "orders_awaiting_shipment") {
            setFilterStatus("AWAITING_SHIPMENT");
            nameFilter_status("AWAITING_SHIPMENT");
        }
        if (data === "orders_awaiting_collection") {
            setFilterStatus("AWAITING_COLLECTION");
            nameFilter_status("AWAITING_COLLECTION");
        }
        if (data === "orders_in_transit") {
            setFilterStatus("IN_TRANSIT");
            nameFilter_status("IN_TRANSIT");
        }
        if (data === "orders_delivered") {
            setFilterStatus("DELIVERED");
            nameFilter_status("DELIVERED");
        }
        if (data === "orders_completed") {
            setFilterStatus("COMPLETED");
            nameFilter_status("COMPLETED");
        }
    };

    const onKeyPress = (e) => {
        if ((/^[0-9.]+$/).test(e)) {
            e.preventDefault();
        }
    }

    const columns = [{
        dataField: '_id',
        text: t("Actions"),
        editable: false,
        formatExtraData: { page, sizePerPage, searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, date_type, dateStart, dateEnd, isLoading, setOrdersSelected },
        formatter: (cell, row, rowIndex, formatExtraData) => (
            <Fragment>
                <div className="d-flex flex-column mb-5">
                    <span className="mb-2">{totalsize - ((page - 1) * sizePerPage) - rowIndex}</span>
                    <span className="mb-2"><FormatterStatus status={row?.order_status} large={true} /></span>
                    <span>
                        {
                            row?.overdue ?
                                <FormatterStatus status={"overdue"} large={true} />
                                : row?.ship_late ?
                                    <FormatterStatus status={"ship_late"} large={true} />
                                    : ""
                        }
                    </span>
                </div>
                {row?.recipient_address?.address_line1 !== "" && <div className="d-flex flex-column">
                    {
                        row?.order_status !== "worked" ?
                            <button onClick={() => { onClickSendToPrinter(row, formatExtraData) }} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2" data-tooltip-id="my-tooltip" data-tooltip-content={t("Send to printer")} disabled={isLoading}>
                                <i className="ki-outline ki-send fs-1 align-bottom"></i>
                            </button>
                            : ""
                    }
                    {
                        row?.order_status === "worked" && (row?.status !== "IN_TRANSIT" && row?.status !== "DELIVERED" && row?.status !== "CANCELLED" && row?.status !== "CANCEL") ?
                            <button onClick={() => { onClickCancelToPrinter(row, formatExtraData) }} className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2" data-tooltip-id="my-tooltip" data-tooltip-content={t("Cancel to printer")} disabled={isLoading}>
                                <i className="ki-outline ki-cross-square fs-1 align-bottom"></i>
                            </button>
                            : ""
                    }
                    {
                        row?.order_status !== "worked" && row?.status !== "CANCELLED" && row?.status !== "CANCEL" ?
                            <button onClick={() => { onClickOpenModalBuyLabel(row, formatExtraData) }} className="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-2" data-tooltip-id="my-tooltip" data-tooltip-content={t("Buy Label")}>
                                <i className="ki-outline ki-handcart fs-1"></i>
                            </button>
                            : ""
                    }
                    <button onClick={() => { onClickOpenModalEditOrderStatus(row, formatExtraData) }} className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm" data-tooltip-id="my-tooltip" data-tooltip-content={t("Change Status Order")}>
                        <i className="ki-outline ki-notepad-edit fs-1"></i>
                    </button>
                    <button
                        onClick={() => {
                            setEditMember(row);
                        }}
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Member Fulfill")}
                    >
                        <i className="ki-outline ki-user fs-2"></i>
                    </button>
                </div>}
            </Fragment>
        )
    }, {
        dataField: 'shops',
        text: t("Order Number / Shop Code"),
        editable: false,
        sort: true,
        style: {
            width: "220px",
            // maxWidth: "300px",
            wordBreak: "break-all"
        },
        formatter: (cell, row, rowIndex) => (
            <Fragment>
                <div className="d-flex flex-column">
                    <span className="fs-lg mb-5">{row?.apiOrderId}</span>
                    <span className="fs-lg mb-5">{row?.status}</span>
                    <span className="mb-5">{cell?.name}</span>
                    <span className="mb-5">{cell?.note} - {cell?.member_note}</span>
                    <span className="mb-5">{list_staff?.find((v, i) => v?.value === row?.createdBy)?.label || ""}</span>
                    <span className="">{formatterCreateAt(row?.create_time * 1000)}</span>
                    <span className="">{row?.returnorders?._id && <h5 className="text-danger">Refund issued</h5>}</span>
                </div>
            </Fragment>
        ),
        filter: textFilter({
            className: 'd-none',
            getFilter: (filter) => {
                nameFilter_shop_code = filter;
            }
        })
    }, {
        dataField: 'buyer_message',
        text: t("Buyer Note"),
        editable: false,
        sort: true,
        style: {
            maxWidth: "300px",
            wordBreak: "break-all"
        },
    }, {
        dataField: 'keywords',
        text: t("Fulfill Note"),
        sort: true,
        style: {
            maxWidth: "300px",
            wordBreak: "break-all"
        },
        formatter: (cell, row, rowIndex) => <Fragment>{cell} <i className="ki-outline ki-notepad-edit fs-2 align-bottom cursor-pointer"></i></Fragment>,
    }, {
        dataField: 'printer',
        text: t("Print partner"),
        editable: false,
        sort: true,
        style: {
            maxWidth: "800px"
        },
        formatExtraData: { page, sizePerPage, searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, date_type, dateStart, dateEnd, printer_tmp },
        formatter: (cell, row, rowIndex, formatExtraData) => (
            <Fragment>
                {
                    row?.line_items.map((v, i) => {
                        return (
                            <Fragment>
                                <div className="mb-3" style={{ minHeight: "220px", maxHeight: "220px", overflow: "auto" }}>
                                    <span className="text-primary fw-semibold d-block fs-7">{v?.sku_name}</span>
                                    <div className="d-flex">
                                        <Select
                                            isDisabled={row?.order_status === "worked"}
                                            isClearable
                                            name="printer"
                                            options={list_printer}
                                            className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                            classNamePrefix="select"
                                            value={list_printer.filter(({ value }) => printer_tmp?.[row._id]?.printer?.[v.id]?.printer === value)}
                                            onChange={(e) => { onChangePrinter(e, row, formatExtraData, v) }}
                                            placeholder={t("Select a printer ... ")}
                                        />
                                        {
                                            printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "flashship" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "mangoteeprints" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "gearment" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "other" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "hmfulfill" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "lenful" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "onospod" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "a2kecom" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "printeeshub" ?
                                                <Fragment>
                                                    <Select
                                                        isDisabled={row?.order_status === "worked"}
                                                        isClearable
                                                        name="manufacturer"
                                                        options={list_manufacturer}
                                                        className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                        classNamePrefix="select"
                                                        value={list_manufacturer.filter(({ value }) => printer_tmp?.[row._id]?.printer?.[v.id]?.manufacturer === value)}
                                                        onChange={(e) => { onChangeManuFacturer(e, row, formatExtraData, v) }}
                                                        placeholder={t("Select a manufacturer ... ")}
                                                        isOptionDisabled={(option) => !option.enabled?.includes(printer_tmp?.[row._id]?.printer?.[v.id]?.printer)}
                                                    />
                                                    <Select
                                                        isDisabled={row?.order_status === "worked"}
                                                        isClearable
                                                        isCreate
                                                        name="type"
                                                        options={list_type}
                                                        className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                        classNamePrefix="select"
                                                        value={list_type.filter(({ value }) => printer_tmp?.[row._id]?.printer?.[v.id]?.type === value)}
                                                        onChange={(e) => { onChangeType(e, row, formatExtraData, v) }}
                                                        placeholder={t("Select a type ... ")}
                                                        isOptionDisabled={(option) => !option.enabled?.includes(printer_tmp?.[row._id]?.printer?.[v.id]?.manufacturer)}
                                                    />
                                                </Fragment>
                                                : ""
                                        }
                                        {
                                            printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "lenful" ?
                                                <Select
                                                    isDisabled={row?.order_status === "worked"}
                                                    isClearable
                                                    name="print_area"
                                                    options={list_printing_area}
                                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                    classNamePrefix="select"
                                                    value={list_printing_area.filter(({ value }) => printer_tmp?.[row._id]?.printer?.[v.id]?.printing_area === value)}
                                                    onChange={(e) => { onChange(e, row, formatExtraData, v, "printing_area") }}
                                                    placeholder={t("Select a printing area ... ")}
                                                    isOptionDisabled={(option) => !option.enabled?.includes(printer_tmp?.[row._id]?.printer?.[v.id]?.printer)}
                                                />
                                                : ""
                                        }
                                    </div>
                                    {
                                        printer_tmp?.[row._id]?.printer?.[v.id]?.type ?
                                            <Fragment>
                                                <div className="d-flex gap-2">
                                                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                            <span className="required">{t("Shipping method")}</span>
                                                        </label>
                                                        {
                                                            printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "flashship" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "mangoteeprints" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "gearment" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "hmfulfill" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "lenful" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "onospod" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "a2kecom" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "printeeshub" ?
                                                                <Select
                                                                    isDisabled={row?.order_status === "worked"}
                                                                    isClearable
                                                                    isCreate
                                                                    name="shipping_method"
                                                                    options={list_shipping_method}
                                                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                                    classNamePrefix="select"
                                                                    value={list_shipping_method.filter(({ value, enabled }) => printer_tmp?.[row._id]?.printer?.[v.id]?.shipping_method === value && enabled?.includes(printer_tmp?.[row._id]?.printer?.[v.id]?.printer))}
                                                                    onChange={(e) => { onChange(e, row, formatExtraData, v, "shipping_method") }}
                                                                    placeholder={t("Select a shipping method ... ")}
                                                                    isOptionDisabled={(option) => !option.enabled?.includes(printer_tmp?.[row._id]?.printer?.[v.id]?.printer)}
                                                                />
                                                                : ""
                                                        }
                                                        {
                                                            printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "other" ?
                                                                <CreatableSelect
                                                                    isDisabled={row?.order_status === "worked"}
                                                                    name="shipping_method"
                                                                    options={list_shipping_method}
                                                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                                    classNamePrefix="select"
                                                                    value={{ "label": printer_tmp?.[row._id]?.printer?.[v.id]?.shipping_method, "value": printer_tmp?.[row._id]?.printer?.[v.id]?.shipping_method }}
                                                                    onChange={(e) => { onChange(e, row, formatExtraData, v, "shipping_method") }}
                                                                    placeholder={t("Select a shipping method ... ")}
                                                                />
                                                                : ""
                                                        }
                                                    </div>
                                                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                            <span className="required">{t("Color")}</span>
                                                        </label>
                                                        {
                                                            printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "flashship" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "mangoteeprints" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "gearment" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "hmfulfill" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "lenful" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "onospod" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "a2kecom" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "printeeshub" ?
                                                                <Select
                                                                    isDisabled={row?.order_status === "worked"}
                                                                    isClearable
                                                                    name="color"
                                                                    options={list_color}
                                                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                                    classNamePrefix="select"
                                                                    value={list_color.filter(({ value }) => printer_tmp?.[row._id]?.printer?.[v.id]?.color === value)}
                                                                    onChange={(e) => { onChange(e, row, formatExtraData, v, "color") }}
                                                                    placeholder={t("Select a color ... ")}
                                                                    isOptionDisabled={(option) => !option.enabled?.includes(printer_tmp?.[row._id]?.printer?.[v.id]?.type)}
                                                                />
                                                                : ""
                                                        }
                                                        {
                                                            printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "other" ?
                                                                <CreatableSelect
                                                                    isDisabled={row?.order_status === "worked"}
                                                                    name="color"
                                                                    options={list_color}
                                                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                                    classNamePrefix="select"
                                                                    value={{ "label": printer_tmp?.[row._id]?.printer?.[v.id]?.color, "value": printer_tmp?.[row._id]?.printer?.[v.id]?.color }}
                                                                    onChange={(e) => { onChange(e, row, formatExtraData, v, "color") }}
                                                                    placeholder={t("Select a color ... ")}
                                                                />
                                                                : ""
                                                        }
                                                    </div>
                                                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                            <span className="required">{t("Size")}</span>
                                                        </label>
                                                        {
                                                            printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "flashship" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "mangoteeprints" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "gearment" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "hmfulfill" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "lenful" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "onospod" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "a2kecom" || printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "printeeshub" ?
                                                                <Select
                                                                    isDisabled={row?.order_status === "worked"}
                                                                    isClearable
                                                                    name="size"
                                                                    options={list_size}
                                                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                                    classNamePrefix="select"
                                                                    value={list_size.filter(({ value }) => printer_tmp?.[row._id]?.printer?.[v.id]?.size === value)}
                                                                    onChange={(e) => { onChange(e, row, formatExtraData, v, "size") }}
                                                                    placeholder={t("Select a size ... ")}
                                                                    isOptionDisabled={(option) => !option.enabled?.includes(printer_tmp?.[row._id]?.printer?.[v.id]?.type)}
                                                                />
                                                                : ""
                                                        }
                                                        {
                                                            printer_tmp?.[row._id]?.printer?.[v.id]?.printer === "other" ?
                                                                <CreatableSelect
                                                                    isDisabled={row?.order_status === "worked"}
                                                                    name="size"
                                                                    options={list_size}
                                                                    className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                                    classNamePrefix="select"
                                                                    value={{ "label": printer_tmp?.[row._id]?.printer?.[v.id]?.size, "value": printer_tmp?.[row._id]?.printer?.[v.id]?.size }}
                                                                    onChange={(e) => { onChange(e, row, formatExtraData, v, "size") }}
                                                                    placeholder={t("Select a size ... ")}
                                                                />
                                                                : ""
                                                        }
                                                    </div>
                                                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                            <span className="">{t("Basecost")}</span>
                                                        </label>
                                                        <div className="input-group">
                                                            <input type="number" className="form-control mw-70px al-input-basecost-order-cus" value={printer_tmp?.[row._id]?.printer?.[v.id]?.base_cost} placeholder="" name="base_cost" onChange={(e) => { onChange(e, row, formatExtraData, v, "base_cost") }} onKeyPress={(e) => { onKeyPress(e) }} disabled={row?.order_status === "worked"} />
                                                            <span className="input-group-text al-input-group-basecost-order-cus">$</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                            : ""
                                    }
                                    {
                                        row?.order_status !== "worked" ?
                                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                                <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSavePrintPartner(row, formatExtraData) }}  >{t("Save")}</button>
                                            </div>
                                            : ""
                                    }
                                </div>
                                {i + 1 !== row?.line_items.length ? <hr /> : ""}
                            </Fragment>
                        );
                    })
                }
            </Fragment>
        ),
        filter: textFilter({
            className: 'd-none',
            getFilter: (filter) => {
                nameFilter_printer = filter;
            }
        })
    }, {
        dataField: 'shipping_label',
        text: t("Shipping Label"),
        sort: true,
        style: {
            maxWidth: "200px",
            wordBreak: "break-all"
        },
        formatter: (cell, row) => (
            <Fragment>
                {cell !== "" ? <Link to={cell} target="_blank">{cell} <i className="ki-outline ki-notepad-edit fs-2 align-bottom cursor-pointer"></i></Link> : <i className="ki-outline ki-notepad-edit fs-2 align-bottom cursor-pointer"></i>}
            </Fragment>
        ),
    }, {
        dataField: 'design',
        text: t("Mockup / Design"),
        editable: false,
        sort: true,
        formatExtraData: { page, sizePerPage, searchText, filter_printer, filter_shop_code, filter_status, filter_work_status, filter_staff, date_type, dateStart, dateEnd },
        formatter: (cell, row, rowIndex, formatExtraData) => (
            <Fragment>
                {
                    row?.line_items.map((v, i) => {
                        return (
                            <Fragment>
                                <div className="row" style={{ minHeight: "220px" }}>
                                    {(row?.mockup?.[v.id]?.Front || row?.mockup?.[v.id]?.Back || row?.mockup?.[v.id]?.Neck || row?.mockup?.[v.id]?.Right || row?.mockup?.[v.id]?.Left || row?.mockup?.[v.id]?.AOP_3D) && <>
                                        <div className="mb-3 col-md-5 w-max-content">
                                            {(row?.mockup?.[v.id]?.Front || row?.mockup?.[v.id]?.Back || row?.mockup?.[v.id]?.Neck) && <>
                                                <div className="d-flex justify-content-center mb-5 w-max-content">
                                                    {row?.mockup?.[v.id]?.Front && <div className="d-flex align-items-center justify-content-center flex-column">
                                                        <span className="h3">Front</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.mockup?.[v.id]?.Front ?
                                                                    <Link to={row?.mockup?.[v.id]?.Front} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.mockup?.[v.id]?.Front) }, largeImage: { src: formatterImage(row?.mockup?.[v.id]?.Front), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />
                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                    {row?.mockup?.[v.id]?.Back && <div className="d-flex  align-items-center justify-content-center flex-column ml-5">
                                                        <span className="h3">Back</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.mockup?.[v.id]?.Back ?
                                                                    <Link to={row?.mockup?.[v.id]?.Back} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.mockup?.[v.id]?.Back) }, largeImage: { src: formatterImage(row?.mockup?.[v.id]?.Back), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                    {row?.mockup?.[v.id]?.Neck && <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                                                        <span className="h3">Neck</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.mockup?.[v.id]?.Neck ?
                                                                    <Link to={row?.mockup?.[v.id]?.Neck} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.mockup?.[v.id]?.Neck) }, largeImage: { src: formatterImage(row?.mockup?.[v.id]?.Neck), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                </div>
                                            </>}
                                            {(row?.mockup?.[v.id]?.Right || row?.mockup?.[v.id]?.Left || row?.mockup?.[v.id]?.AOP_3D) && <>
                                                <div className="d-flex justify-content-center mb-5 w-max-content">
                                                    {row?.mockup?.[v.id]?.Right && <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                                                        <span className="h3">Right</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.mockup?.[v.id]?.Right ?
                                                                    <Link to={row?.mockup?.[v.id]?.Right} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.mockup?.[v.id]?.Right) }, largeImage: { src: formatterImage(row?.mockup?.[v.id]?.Right), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                    {row?.mockup?.[v.id]?.Left && <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                                                        <span className="h3">Left</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.mockup?.[v.id]?.Left ?
                                                                    <Link to={row?.mockup?.[v.id]?.Left} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.mockup?.[v.id]?.Left) }, largeImage: { src: formatterImage(row?.mockup?.[v.id]?.Left), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                    {row?.mockup?.[v.id]?.AOP_3D && <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                                                        <span className="h3">AOP3D</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.mockup?.[v.id]?.AOP_3D ?
                                                                    <Link to={row?.mockup?.[v.id]?.AOP_3D} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.mockup?.[v.id]?.AOP_3D) }, largeImage: { src: formatterImage(row?.mockup?.[v.id]?.AOP_3D), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                </div>
                                            </>}
                                        </div>
                                    </>}
                                    {(row?.mockup?.[v.id]?.Front || row?.mockup?.[v.id]?.Back || row?.mockup?.[v.id]?.Neck || row?.mockup?.[v.id]?.Right || row?.mockup?.[v.id]?.Left || row?.mockup?.[v.id]?.AOP_3D) && <>
                                        <div className="mb-3 col-md-1 w-max-content d-flex justify-content-center align-items-center">
                                            /
                                        </div>
                                    </>}
                                    {(row?.design?.[v.id]?.Front || row?.design?.[v.id]?.Back || row?.design?.[v.id]?.Neck || row?.design?.[v.id]?.Right || row?.design?.[v.id]?.Left || row?.design?.[v.id]?.AOP_3D) && <>
                                        <div className="mb-3 col-md-5 w-max-content">
                                            {(row?.design?.[v.id]?.Front || row?.design?.[v.id]?.Back || row?.design?.[v.id]?.Neck) && <>
                                                <div className="d-flex justify-content-center mb-5 w-max-content">
                                                    {row?.design?.[v.id]?.Front && <div className="d-flex align-items-center justify-content-center flex-column">
                                                        <span className="h3">Front</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.design?.[v.id]?.Front ?
                                                                    <Link to={row?.design?.[v.id]?.Front} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.design?.[v.id]?.Front) }, largeImage: { src: formatterImage(row?.design?.[v.id]?.Front), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                    {row?.design?.[v.id]?.Back && <div className="d-flex  align-items-center justify-content-center flex-column ml-5">
                                                        <span className="h3">Back</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.design?.[v.id]?.Back ?
                                                                    <Link to={row?.design?.[v.id]?.Back} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.design?.[v.id]?.Back) }, largeImage: { src: formatterImage(row?.design?.[v.id]?.Back), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                    {row?.design?.[v.id]?.Neck && <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                                                        <span className="h3">Neck</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.design?.[v.id]?.Neck ?
                                                                    <Link to={row?.design?.[v.id]?.Neck} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.design?.[v.id]?.Neck) }, largeImage: { src: formatterImage(row?.design?.[v.id]?.Neck), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                </div>
                                            </>}
                                            {(row?.design?.[v.id]?.Right || row?.design?.[v.id]?.Left || row?.design?.[v.id]?.AOP_3D) && <>
                                                <div className="d-flex justify-content-center mb-5 w-max-content">
                                                    {row?.design?.[v.id]?.Right && <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                                                        <span className="h3">Right</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.design?.[v.id]?.Right ?
                                                                    <Link to={row?.design?.[v.id]?.Right} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.design?.[v.id]?.Right) }, largeImage: { src: formatterImage(row?.design?.[v.id]?.Right), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                    {row?.design?.[v.id]?.Left && <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                                                        <span className="h3">Left</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.design?.[v.id]?.Left ?
                                                                    <Link to={row?.design?.[v.id]?.Left} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.design?.[v.id]?.Left) }, largeImage: { src: formatterImage(row?.design?.[v.id]?.Left), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                    {row?.design?.[v.id]?.AOP_3D && <div className="d-flex  align-items-center justify-content-center flex-column mr-5">
                                                        <span className="h3">AOP3D</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                row?.design?.[v.id]?.AOP_3D ?
                                                                    <Link to={row?.design?.[v.id]?.AOP_3D} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(row?.design?.[v.id]?.AOP_3D) }, largeImage: { src: formatterImage(row?.design?.[v.id]?.AOP_3D), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" />
                                                            }
                                                        </div>
                                                    </div>}
                                                </div>
                                            </>}
                                        </div>
                                    </>}
                                    {
                                        row?.order_status !== "worked" ?
                                            <div className="mb-3 col-md-1 w-max-content d-flex justify-content-center align-items-center">
                                                <i className="ki-outline ki-notepad-edit fs-2 align-bottom cursor-pointer float-end" onClick={() => { onClickOpenModalEditDesign(row, formatExtraData, v); }}></i>
                                            </div>
                                            : ""
                                    }
                                </div>
                                {i + 1 !== row?.line_items.length ? <hr /> : ""}
                            </Fragment>
                        );
                    })
                }
            </Fragment >
        ),
    }, {
        dataField: 'line_items',
        text: t("Title"),
        editable: false,
        sort: true,
        formatter: (cell, row) => (
            <Fragment>
                {
                    row?.line_items.map((v, i) => {
                        return (
                            <Fragment>
                                <div className="mb-3" style={{ minHeight: "220px" }}>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            v?.sku_image ?
                                                <Link to={v?.sku_image} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: v?.sku_image }, largeImage: { src: v?.sku_image, width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />

                                                </Link>
                                                :
                                                <img src={require("components/assets/media/misc/image.png")} alt="" />
                                        }
                                    </div>
                                    <div className="d-inline-block">
                                        <span className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">{subStringText(v?.product_name)}</span>
                                        <span className="text-muted fw-semibold d-block fs-7">{v?.sku_name}</span>
                                        <span className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">{v?.sale_price} {v?.currency}</span>
                                        <div className="">
                                            {v?.seller_sku !== "" ? `Seller SKU: ${v?.seller_sku}` : ""}
                                        </div>
                                    </div>
                                </div>
                                {i + 1 !== row?.line_items.length ? <hr /> : ""}
                            </Fragment>
                        );
                    })
                }
            </Fragment>
        ),
    }, {
        dataField: 'recipient_address',
        text: t("Name / Address"),
        editable: false,
        sort: true,
        style: {
            maxWidth: "300px",
            wordBreak: "break-all"
        },
        formatter: (cell, row) => (
            <Fragment>
                <strong>{cell?.name}</strong> - {cell?.phone_number}<br />
                {cell?.address_detail}, {cell?.address_line2 ? cell?.address_line2 + ", " : ""} {cell?.district_info?.[3]?.address_name ? cell?.district_info?.[3]?.address_name + ", " : ""} {cell?.district_info?.[2]?.address_name && cell?.district_info?.[2]?.address_level_name !== "County" ? cell?.district_info?.[2]?.address_name + ", " : ""} {cell?.district_info?.[1]?.address_name ? cell?.district_info?.[1]?.address_name + ", " : ""} {cell?.district_info?.[0]?.address_name ? cell?.district_info?.[0]?.address_name + ", " : ""} {cell?.postal_code ? cell?.postal_code : ""}
                {cell?.delivery_preferences?.drop_off_location && cell?.delivery_preferences?.drop_off_location !== "" ? <><br /><strong>Delivery instructions:</strong>{cell?.delivery_preferences?.drop_off_location}</> : ""}
            </Fragment>
        ),
    }, {
        dataField: 'quantity',
        text: t("Quantity"),
        editable: false,
        sort: true,
        formatter: (cell, row, rowIndex) => (
            <Fragment>
                {/* {row?.orderstatements?.sku_statement_transactions ? Object.values(row?.orderstatements?.sku_statement_transactions).reduce((a, b) => +a + +b.quantity, 0) : 0} */}
                {
                    row?.line_items.map((v, i) => {
                        return (
                            <Fragment>
                                <div className="mb-3" style={{ minHeight: "220px" }}>
                                    1
                                </div>
                                {i + 1 !== row?.line_items.length ? <hr /> : ""}
                            </Fragment>
                        );
                    })
                }
            </Fragment>
        ),
    }, {
        dataField: 'payment',
        text: t("Price"),
        editable: false,
        sort: true,
        formatter: (cell, row) => cell?.total_amount || 0
    }, {
        dataField: 'settlement_amount',
        text: t("Est. amount"),
        editable: false,
        sort: true,
        formatter: (cell, row) =>
            <>
                {
                    row?.shipping_type === "TIKTOK" ?
                        (((+row?.payment?.original_total_product_price - +row?.payment?.seller_discount) - 6 / 100 * (+row?.payment?.total_amount + +row?.payment?.platform_discount - +row?.payment?.tax)).toFixed(2)) :
                        ((+row?.payment?.original_total_product_price - +row?.payment?.seller_discount + +row?.payment?.shipping_fee) - 6 / 100 * (+row?.payment?.total_amount + +row?.payment?.platform_discount - +row?.payment?.tax)).toFixed(2)
                }
            </>
    }, {
        dataField: 'tracking_number',
        text: t("Tracking Number"),
        sort: true,
        formatter: (cell, row, rowIndex) => (
            <Fragment>
                {
                    row?.line_items.map((v, i) => {
                        return (
                            <Fragment>
                                <div className="mb-3" style={{ minHeight: "220px" }}>
                                    {cell} <i className="ki-outline ki-notepad-edit fs-2 align-bottom cursor-pointer"></i>
                                </div>
                                {i + 1 !== row?.line_items.length ? <hr /> : ""}
                            </Fragment>
                        );
                    })
                }
            </Fragment>
        ),
    }, {
        dataField: 'fulfillment_order_id',
        text: t("Fulfillment Order ID"),
        sort: true,
        formatter: (cell, row, rowIndex) => <Fragment>{cell} <i className="ki-outline ki-notepad-edit fs-2 align-bottom cursor-pointer"></i></Fragment>,
    }, {
        dataField: 'net_revenue',
        text: t("Net revenue"),
        editable: false,
        sort: true,
        formatter: (cell, row, rowIndex) => row?.orderstatements?.revenue_amount || 0,
    }, {
        dataField: 'base_cost',
        text: t("Base Cost"),
        editable: false,
        sort: true,
        // formatter: (cell, row, rowIndex) => <Fragment>{row?.printer ? Object.values(row?.printer).reduce((a, b) => +a + +b.base_cost, 0) : 0}</Fragment>,
        formatter: (cell, row) => cell || 0
    }, {
        dataField: 'design_fee',
        text: t("Design Fee"),
        editable: false,
        sort: true,
        formatter: (cell, row, rowIndex) => <Fragment>{cell ? Object.values(cell).reduce((a, b) => +a + +b, 0) : 0}</Fragment>,
    }, {
        dataField: 'net_profits',
        text: t("Net Profits"),
        editable: false,
        sort: true,
        formatter: (cell, row, rowIndex) => row?.orderstatements?.net_sales_amount || 0,
    }, {
        dataField: 'status',
        text: t("Status"),
        sort: true,
        editable: false,
        headerClasses: "d-none",
        classes: "d-none",
        filter: textFilter({
            className: 'd-none',
            getFilter: (filter) => {
                nameFilter_status = filter;
            }
        })
    }, {
        dataField: 'order_status',
        text: t("Order Status"),
        sort: true,
        editable: false,
        headerClasses: "d-none",
        classes: "d-none",
        filter: textFilter({
            className: 'd-none',
            getFilter: (filter) => {
                nameFilter_work_status = filter;
            }
        })
    }, {
        dataField: 'staff',
        text: t("Staff"),
        sort: true,
        editable: false,
        headerClasses: "d-none",
        classes: "d-none",
        filter: textFilter({
            className: 'd-none',
            getFilter: (filter) => {
                nameFilter_staff = filter;
            }
        })
    }];

    const groupButton = () => {
        return (
            <div className="mb-5">
                <button onClick={() => { onClickSendToPrinterOrdersSelected() }} type="button" className="btn btn-primary btn-sm me-2 mb-2" disabled={orders_selected.length <= 0}>
                    <i className="ki-outline ki-send fs-2"></i> {t("Send to printer")}
                </button>
                <button onClick={() => { onClickCancelToPrinterOrdersSelected() }} type="button" className="btn btn-danger btn-sm me-2 mb-2" disabled={orders_selected.length <= 0}>
                    <i className="ki-outline ki-cross-square fs-2"></i> {t("Cancel to printer")}
                </button>
                <button onClick={() => { onClickOpenModalBuyLabelOrdersSelected() }} type="button" className="btn btn-info btn-sm me-2 mb-2" disabled={orders_selected.length <= 0}>
                    <i className="ki-outline ki-handcart fs-2"></i> {t("Buy Label")}
                </button>
                <button onClick={() => { onClickExportOrders() }} type="button" className="btn btn-success btn-sm me-2 mb-2">
                    <i className="fa fa-file-excel-o fs-2"></i> {t("Export")}
                </button>
                <CSVLink filename={"orders_" + formatterCreateAt((new Date()).getTime(), "dmy", "Asia/Ho_Chi_Minh") + ".csv"} data={export_orders_data}>
                    <span ref={btnRef} />
                </CSVLink>
                <button onClick={() => { onClickOpenModalImportTracking() }} type="button" className="btn btn-warning btn-sm me-2 mb-2">
                    <i className="fa fa-file-excel-o fs-2"></i> {t("Import Tracking")}
                </button>
                <Select
                    isClearable
                    name="printer"
                    options={list_printer}
                    className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                    classNamePrefix="select"
                    value={list_printer.filter(({ value }) => value === filter_printer)}
                    onChange={(e) => { onChangeFilterPrinter(e) }}
                    placeholder="Select a printer ... "
                />
                <Select
                    isClearable
                    name="shop_code"
                    options={list_shop}
                    className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                    classNamePrefix="select"
                    value={list_shop.filter(({ value }) => value === filter_shop_code)}
                    onChange={(e) => { onChangeFilterShopCode(e) }}
                    placeholder="Select a shop code ... "
                />
                <Select
                    isClearable
                    name="staff"
                    options={list_staff}
                    className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                    classNamePrefix="select"
                    value={list_staff.filter(({ value }) => value === filter_staff)}
                    onChange={(e) => { onChangeFilterStaff(e) }}
                    placeholder="Select a staff ... "
                />
                {
                    !tab_order ?
                        <Select
                            isClearable
                            name="status"
                            options={list_status}
                            className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                            classNamePrefix="select"
                            value={list_status.filter(({ value }) => value === filter_status)}
                            onChange={(e) => { onChangeFilterStatus(e) }}
                            placeholder="Select a status ... "
                        />
                        : ""
                }
                <Select
                    isClearable
                    name="work_status"
                    options={list_work_status}
                    className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                    classNamePrefix="select"
                    value={list_work_status.filter(({ value }) => value === filter_work_status)}
                    onChange={(e) => { onChangeFilterWorkStatus(e) }}
                    placeholder="Select a work status ... "
                />
                {
                    date_type === "custom" ?
                        <DateRangePicker onCallback={handleEventDate} initialSettings={{ startDate: formatterCreateAt(dateStart, "dmy", "Asia/Ho_Chi_Minh"), endDate: formatterCreateAt(dateEnd, "dmy", "Asia/Ho_Chi_Minh"), showDropdowns: true, locale: { format: 'DD/MM/YYYY' } }}>
                            <input type="text" className="form-control al-form-control-daterangepicker me-2" />
                        </DateRangePicker>
                        : ""
                }
                <Select
                    name="date_type"
                    options={list_date_type}
                    className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table"
                    classNamePrefix="select"
                    value={list_date_type.filter(({ value }) => value === date_type)}
                    onChange={(e) => { onChangeDateType(e) }}
                    placeholder="Select a date ... "
                />
            </div>
        );
    }

    const handleTableChange = (type, { page, sizePerPage, searchText, filters }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(10);
            setSearchText(searchText);
        } else if (type === "filter") {
            var filterVal = "";
            if (filters.printer) {
                filterVal = filters.printer.filterVal;
            }
            var filterVal2 = "";
            if (filters.shops) {
                filterVal2 = filters.shops.filterVal;
            }
            var filterVal3 = "";
            if (filters.status) {
                filterVal3 = filters.status.filterVal;
            }
            var filterVal4 = "";
            if (filters.staff) {
                filterVal4 = filters.staff.filterVal;
            }
            setPage(1);
            setSizePerPage(10);
            setFilterPrinter(filterVal);
            setFilterShopCode(filterVal2);
            setFilterStatus(filterVal3);
            setFilterStaff(filterVal4);
        } else {
            setPage(page || 1);
            setSizePerPage(sizePerPage);
        }
    }

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    }

    const onSelect = (row, isSelect) => {
        var orders_selected_tmp = orders_selected;
        if (isSelect) {
            orders_selected_tmp.push(row._id);
        } else {
            orders_selected_tmp = removeSelect(orders_selected_tmp, row._id);
        }
        var new_arr = JSON.stringify(orders_selected_tmp);
        setOrdersSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        var orders_selected_tmp = orders_selected;
        if (isSelect) {
            orders_selected_tmp = row.map((v, i) => v._id);
        } else {
            orders_selected_tmp = [];
        }
        setOrdersSelected(orders_selected_tmp);
    };

    // const getNonSelectableRows = (rows) => {
    //     return rows
    //         .filter((row) => row?.order_status === "worked")
    //         .reduce((order, row) => {
    //             order.push(row._id);
    //             return order;
    //         }, []);
    // }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selected: orders_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        // nonSelectable: getNonSelectableRows(listorders),
        selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
            <div className="d-flex">
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) {
                            input.indeterminate = indeterminate;
                            input.checked = checked;
                        }
                    }}
                    onChange={onChange}
                />
                <span style={{ marginLeft: '8px' }}>({orders_selected.length})</span>
            </div>
        ),
    };

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: totalsize || 10
        }
    ];

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-0 pb-0">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "orders_all" })} to="/admin/orders" onClick={(e) => { onClick("orders_all"); }}>
                                {t("All")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "orders_on_hold" })} to="/admin/orders?tab=orders_on_hold" onClick={(e) => { onClick("orders_on_hold"); }}>
                                {t("On Hold")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "orders_awaiting_shipment" })} to="/admin/orders?tab=orders_awaiting_shipment" onClick={(e) => { onClick("orders_awaiting_shipment"); }}>
                                {t("Awaiting Shipment")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "orders_awaiting_collection" })} to="/admin/orders?tab=orders_awaiting_collection" onClick={(e) => { onClick("orders_awaiting_collection"); }}>
                                {t("Awaiting Collection")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "orders_in_transit" })} to="/admin/orders?tab=orders_in_transit" onClick={(e) => { onClick("orders_in_transit"); }}>
                                {t("In Transit")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "orders_delivered" })} to="/admin/orders?tab=orders_delivered" onClick={(e) => { onClick("orders_delivered"); }}>
                                {t("Delivered")}
                            </Link>
                        </li>
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "orders_completed" })} to="/admin/orders?tab=orders_completed" onClick={(e) => { onClick("orders_completed"); }}>
                                {t("Completed")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body p-9">
                    <ReactTable columns={columns} data={listorders} groupButton={groupButton} handleTableChange={handleTableChange} page={page} sizePerPage={sizePerPage} totalSize={totalSize} alClassName="table-layout-inherit w-max-content al-d-none-option-react-select al-table-order-sticky" isSelectRow={true} selectRow={selectRow} sizePerPageList={sizePerPageList} isCellEdit={true} handleBeforeSaveCell={handleBeforeSaveCell} isSearchBar={false} isSearchBarQuick={true} />
                    {isOpenModalEditDesign ? (<Modaleditdesign openModal={onClickOpenModalEditDesign} isOpenModalEditDesign={isOpenModalEditDesign} editdesign={editdesign} />) : ("")}
                    {isOpenModalEditOrderStatus ? (<Modaleditorderstatus openModal={onClickOpenModalEditOrderStatus} isOpenModalEditOrderStatus={isOpenModalEditOrderStatus} editorderstatus={editorderstatus} />) : ("")}
                    {isOpenModalBuyLabel ? (<Modalbuylabel openModal={onClickOpenModalBuyLabel} isOpenModalBuyLabel={isOpenModalBuyLabel} buylabel={buylabel} />) : ("")}
                    {isOpenModalImportTracking ? <Modalimporttracking openModal={onClickOpenModalImportTracking} isOpenModalImportTracking={isOpenModalImportTracking} /> : ''}
                </div>
            </div>
            <ModalUpdateMember
                isOpen={!!editMember}
                data={editMember}
                onClose={() => setEditMember(null)}
                onSubmit={(data) =>
                    saveEditOrderMember({
                        ...data,
                        page,
                        sizePerPage,
                        searchText,
                        filter_printer,
                        filter_shop_code,
                        filter_status,
                        filter_work_status,
                        filter_staff,
                        dateStart,
                        dateEnd,
                    }, () => setEditMember(null))
                }
            />
        </Fragment>
    );
}

Listorders.propTypes = {
    formatterCreateAt: PropTypes.func.isRequired,
    getListOrders: PropTypes.func.isRequired,
    subStringText: PropTypes.func.isRequired,
    saveEditOrder: PropTypes.func.isRequired,
    sendToPrinter: PropTypes.func.isRequired,
    cancelToPrinter: PropTypes.func.isRequired,
    FormatterStatus: PropTypes.func.isRequired,
    formatterImage: PropTypes.func.isRequired,
    getShops: PropTypes.func.isRequired,
    getStaffs: PropTypes.func.isRequired,
    exportOrder: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    order: state.order,
    shop: state.shop,
    team: state.team
});
const mapDispatchToProps = {
    formatterCreateAt,
    getListOrders,
    subStringText,
    saveEditOrder,
    sendToPrinter,
    cancelToPrinter,
    FormatterStatus,
    formatterImage,
    saveEditOrderMember,
    getShops,
    getStaffs,
    exportOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(Listorders);