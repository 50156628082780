import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalUploadCSV({ onSubmit }) {
    const [isOpen, setIsOpen] = useState(false);
    const [is_sharing, setIsSharing] = useState("private");
    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "is_sharing") {
            setIsSharing(e.target.value);
        }
    };

    const handleSubmit = async () => {
        await onSubmit(is_sharing);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <button
                className="btn btn-sm btn-success"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                <i className="ki-outline ki-share fs-2"></i>{t("Public")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Email Receive?")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <select
                        className="form-select"
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="Sharing"
                        name="is_sharing"
                        onChange={(e) => { onChange(e) }}
                    >
                        <option value="">Private</option>
                        <option value="shared">Public</option>
                    </select>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Share")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUploadCSV);
