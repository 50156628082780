import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { importTracking } from "actions/orderActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

function Modalimporttracking(props) {
    var { isLoading } = props.order;
    var { isOpenModalImportTracking, openModal, importTracking, _id } = props;
	const [file_to_import, setFileToImport] = useState("");
    const { t } = useTranslation();
    var data_template = [{
        apiOrderId: "",
        tracking_number: "",
    }];

    useEffect(() => {
        if (file_to_import !== "") {
            const formData = new FormData();
            formData.append('file', file_to_import);

            importTracking({_id, formData}, openModal);
        }
    }, [file_to_import]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChange = (e) => {
        if (e.target.name === "file_to_import") {
            setFileToImport(e.target.files[0]||"");
        }
	};

	const onOpenModal = () => {
		openModal();
	};

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalImportTracking}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Import Tracking")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="">{t("Select file to import")}</span>
                        </label>
                        <div className="al-custom-file">
                            <input className="al-custom-file-input" type="file" name="file_to_import" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => { onChange(e) }} />
                            <label className="al-custom-file-label">Choose file</label>
                        </div>
                    </div>
                    <CSVLink filename={"import_tracking_template.csv"} data={data_template} className="btn btn-primary btn-sm me-2 mb-2"><i className="fa fa-file-excel-o mr-1"></i>{t("Export Demo")}</CSVLink>
                    <div className="form-text">
                        {t("*Required fields: apiOrderId, tracking_number")}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modalimporttracking.propTypes = {
	importTracking: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	order: state.order
});
const mapDispatchToProps = {
    importTracking
}

export default connect(mapStateToProps, mapDispatchToProps)(Modalimporttracking);