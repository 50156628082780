import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import cx from "classnames";
import Swal from "sweetalert2";

function Main() {
    const [settings, setSettings] = useState(localStorage.getItem("extension_settings") ? JSON.parse(localStorage.getItem("extension_settings")) : {});
    const [tab, setTab] = useState("amazon");
    const { t } = useTranslation();

    const onChange = (e) => {
        var settings_tmp = { ...settings };
        if (!settings_tmp[tab]) {
            settings_tmp[tab] = {};
        }
        if (!settings_tmp[tab][e.target.name]) {
            settings_tmp[tab][e.target.name] = "";
        }
        settings_tmp[tab][e.target.name] = e.target.value;

        setSettings(settings_tmp);
    };

    const onClick = (data) => {
        setTab(data);
    };

    const onClickSaveEdit = () => {
        Swal.fire({
            title: t("Are you sure?"),
            text: t("Are you sure to save changes?"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("Sure"),
            cancelButtonText: t("Cancel")
        }).then((result) => {
            if (result.value) {
                localStorage.setItem("extension_settings", JSON.stringify(settings));
                Swal.fire(t("Success"), t("Change settings successfully!"), 'success');
            }
        })
    };

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-0 pb-0">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item mt-2">
                            <Link className={cx("nav-link text-active-primary ms-0 me-10 py-5", { active: tab === "amazon" })} to="/admin/extension-settings" onClick={(e) => { onClick("amazon"); }}>
                                {t("Amazon")}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card mb-5 mb-xl-10">
                <div id="kt_account_settings_profile_details" className="collapse show">
                    <div className="card-body border-top p-9">
                        {
                            tab === "amazon" ?
                                <Fragment>
                                    <div className="row mb-6">
                                        <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                            <span className="">{t("Crawl type")}</span>
                                        </label>
                                        <div className="col-lg-10 m-auto">
                                            <span className="me-10">
                                                <input class="form-check-input me-3" type="radio" value="0" id="crawl_type_0" name="crawl_type" checked={settings?.["amazon"]?.crawl_type === "0" || typeof settings?.["amazon"]?.crawl_type === "undefined"} onChange={(e) => { onChange(e) }} />
                                                <label className="form-check-label" htmlFor="crawl_type_0">{t("All Variants")}</label>
                                            </span>
                                            <span className="me-10">
                                                <input class="form-check-input me-3" type="radio" value="1" id="crawl_type_1" name="crawl_type" checked={settings?.["amazon"]?.crawl_type === "1"} onChange={(e) => { onChange(e) }} />
                                                <label className="form-check-label" htmlFor="crawl_type_1">{t("Only 1 Variant")}</label>
                                            </span>
                                        </div>
                                    </div>
                                </Fragment>
                            : ""
                        }
                    </div>

                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="submit" className="btn btn-primary" onClick={() => { onClickSaveEdit() }}  >{t("Save Changes")}</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
