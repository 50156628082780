const AppRegions = {
    vi: "VN",
    us: "US",
};

const TeamRole = {
    Staff: "staff",
    Fulfill: "fulfill",
    ShopManager: "shopManager",
};

const ListDateType = [
    { label: "Alltime", value: "" },
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last week", value: "last_week" },
    { label: "Last month", value: "last_month" },
    { label: "Last year", value: "last_year" },
    { label: "This week", value: "this_week" },
    { label: "This month", value: "this_month" },
    { label: "This year", value: "this_year" },
    { label: "Custom", value: "custom" },
];

const CurrencyTiktok = {
    EUR: "France, Germany, Ireland, Italy, Spain",
    GBP: "United Kingdom",
    IDR: "Indonesia",
    MXN: "Mexico",
    MYR: "Malaysia",
    PHP: "Philippines",
    SGD: "Singapore",
    THB: "Thailand",
    USD: "United States",
    VND: "Vietna",
};

export { AppRegions, ListDateType, TeamRole, CurrencyTiktok };
