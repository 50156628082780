import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";


const getConfigLarkBase = () => (dispatch, getState) => {
	dispatch({ type: 'LOADING_LARKBASE', payload: true });
	axios.get(`/api/larkbase`, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_LARKBASE', payload: false });

		if (res.data.status === 200) {
			dispatch({
				type: 'GET_LARKBASE',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_LARKBASE', payload: false });
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

const saveConfigLarkBase = (data) => (dispatch, getState) => {
	var body = {
		data
	};
	Swal.fire({
		title: "Are you sure?",
		text: "Are you sure to edit config?",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: "Sure",
		cancelButtonText: "Cancel"
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_LARKBASE', payload: true });
			axios.post('/api/larkbase', body, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_LARKBASE', payload: false });
				if (res.data.status === 200) {
					dispatch(getConfigLarkBase());
					Swal.fire("Success", res.data.message, 'success');
				} else {
					Swal.fire("Error", res.data.message, 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_LARKBASE', payload: false });
				Swal.fire("Error", "An error occurred!", 'error');
			});
		}
	})
};

export {
	getConfigLarkBase,
	saveConfigLarkBase
};
