import { useMemo, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { scaleNumber } from "components/Function";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale, ChartDataLabels);

const OnHold = ({ data, scaleNumber }) => {
    const chartRef = useRef(null);
    const dataFormat = useMemo(() => {
        return {
            labels: [
                "Order delivered and awaiting settlement",
                "Waiting for package delivery",
                "Waiting for completed refund/return",
                "Reserve",
            ],
            datasets: [
                {
                    data: [
                        data.totalAwaitSettlement?.toFixed(2) || 0,
                        data.totalWaitingDelivered?.toFixed(2) || 0,
                        data.totalAwaitRefundReturn?.toFixed(2) || 0,
                        data.reserve?.toFixed(2) || 0,
                    ],
                    backgroundColor: ["#ff860d", "#369afe", "#f24141", "#23b723"],
                    hoverBackgroundColor: ["#cf955b", "#81aad4", "#d16464", "#78b078"],
                },
            ],
        };
    }, [data]);

    useEffect(() => {
        chartRef.current.update();
    }, [data]);

    return (
        <div className="card mb-5 mb-xl-10">
            {/* <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_account_profile_details"
                aria-expanded="true"
                aria-controls="kt_account_profile_details"
            >
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">OnHold</h3>
                </div>
            </div> */}
            <div className="card-body border-top p-9">
                {/* <h3 className="mt-8">Total OnHold: ${data?.totalOnHold?.toFixed(2)}</h3> */}
                <h3 className="mt-8">
                    Total OnHold: $
                    {((
                        +data?.totalAwaitSettlement +
                        +data.totalWaitingDelivered +
                        +data.totalAwaitRefundReturn +
                        +data?.reserve
                    ) || 0).toFixed(2)}
                </h3>

                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <Doughnut
                            data={dataFormat}
                            ref={chartRef}
                            options={{
                                responsive: true,
                                plugins: {
                                    title: {
                                        display: true,
                                        text: "On Hold",
                                    },
                                    legend: {
                                        display: true,
                                        position: "top",
                                    },
                                    datalabels: {
                                        color: "#fff", // Màu sắc của các số liệu
                                        font: {
                                            weight: "bold", // Kiểu chữ cho số liệu
                                            size: 18, // Kích thước chữ
                                        },
                                        formatter: (value) => value, // Hàm định dạng số liệu, có thể tùy chỉnh
                                    },
                                },
                                aspectRatio: 1,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = { scaleNumber };

export default connect(mapStateToProps, mapDispatchToProps)(OnHold);
