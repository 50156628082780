const initialState = {
	isLoading: false,
	larkConfig: {},
};

function larkBaseReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_LARKBASE':
			return {
				...state,
				isLoading: action.payload
			};
		case 'GET_LARKBASE':
			return {
				...state,
				larkConfig: action.payload.larkConfig,
			};		
		default:
		  return state;
    }
}

export default larkBaseReducer;
