import { t } from "i18next";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
const { SearchBar } = Search;

const SearchBarCustom = (props) => {
	var input;
	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			props.onSearch(input.value);
		}
	};

	return (
		<label htmlFor="search-bar-0" className="search-label">
			<span id="search-bar-0-label" className="sr-only">{t("Enter to search this table")}</span>
			<input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder={t("Enter to search...")} ref={ n => input = n } onKeyDown={(e) => { handleKeyDown(e) }} />
		</label>
	);
};

const ReactTable = (props) => {
	var { keyField = "_id", data, columns, groupButton, handleTableChange, page, sizePerPage, totalSize, expandRow, alClassName, isSearchBar = true, isCellEdit = false, handleBeforeSaveCell, isSelectRow = false, selectRow, remote = { search: true, pagination: true, sort: false }, sizePerPageList, defaultSorted, isSearchBarQuick = false } = props;

	return (
		<ToolkitProvider keyField={keyField} data={data} columns={columns} search >
			{
				props_table => (
					<div>
						{ isSearchBarQuick ? <SearchBar {...props_table.searchProps} /> : "" }
						{ isSearchBar ? <SearchBarCustom { ...props_table.searchProps } /> : "" }
						{typeof groupButton === "function" ? groupButton() : ""}
						{
							isSelectRow ?
								<BootstrapTable classes={alClassName} remote={remote} {...props_table.baseProps} pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })} filter={filterFactory()} defaultSorted={defaultSorted} onTableChange={handleTableChange} expandRow={expandRow ? expandRow : {}} cellEdit={ isCellEdit ? cellEditFactory({ mode: 'click', blurToSave: true, beforeSaveCell: handleBeforeSaveCell }) : {} } wrapperClasses="table-responsive"  noDataIndication={() => 'No data to display'}  striped hover condensed selectRow={ selectRow }/>
							: <BootstrapTable classes={alClassName} remote={remote} {...props_table.baseProps} pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })} filter={filterFactory()} defaultSorted={defaultSorted} onTableChange={handleTableChange} expandRow={expandRow ? expandRow : {}} cellEdit={ isCellEdit ? cellEditFactory({ mode: 'click', blurToSave: true, beforeSaveCell: handleBeforeSaveCell }) : {} } wrapperClasses="table-responsive" noDataIndication={() => 'No data to display'} striped hover condensed />
						}
					</div>
				)
			}
		</ToolkitProvider>
	);
};

const ReactTableDataGrid = (props) => {
	var { idProperty = "_id", columns, dataSource, emptyText = "", onSkipChange = "", skip = 0, defaultLimit = 50, editable = false, onEditComplete = "", rowHeight = null, handle = "", checkboxColumn = false, onSelectionChange = "" } = props;
	const gridStyle = {
		minHeight: "calc(100vh - 250px)",
		textAlign: "center"
	}

	return (
		<ReactDataGrid idProperty={idProperty} columns={columns} dataSource={dataSource} emptyText={emptyText} style={gridStyle} showColumnMenuGroupOptions={false} showColumnMenuLockOptions={false} showColumnMenuSortOptions={false} enableColumnAutosize={false} pagination defaultLimit={defaultLimit} onSkipChange={onSkipChange} skip={skip} editable={editable} onEditComplete={onEditComplete} rowHeight={rowHeight} handle={handle} checkboxColumn={checkboxColumn} onSelectionChange={onSelectionChange} />
	);
};

export { ReactTable, ReactTableDataGrid }