const initialState = {
	isLoading: false,
};

function fulfillmentReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_FULFILLMENT':
			return {
				...state,
				isLoading: action?.payload
			};
		default:
		  return state;
    }
}

export default fulfillmentReducer;
