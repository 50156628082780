import { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import cx from "classnames";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import { ReactTable } from "components/Table";
import { ModalChangeText, ModalTemplate, ModalAddImages, ModalEditDescriptions, ModalEditInformations } from "../modal";
import { countString, formatterCreateAt, scaleNumber } from "components/Function";
import { createProductsByTemplate } from "actions/productActions";
import { changeTitleByAI } from "actions/aiActions";
import { CSVLink } from "react-csv";
import ReactImageMagnify from 'react-image-magnify';
import Loading from "components/Loading";

function Main({ createProductsByTemplate, user, countString, formatterCreateAt, scaleNumber, changeTitleByAI, ai }) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const { t } = useTranslation();
    const [list, setList] = useState(localStorage.getItem("extension_drop_list_temu") ? JSON.parse(localStorage.getItem("extension_drop_list_temu")) : []);
    const [products_selected, setProductsSelected] = useState([]);
    const [products_selected_export, setProductsSelectedExport] = useState([]);
    const [initiateDownload, setInitiateDownload] = useState(false);
    const btnRef = useRef(null);
    var { expired_at } = user.user;
    var { isLoading } = ai;

    useEffect(() => {
        localStorage.setItem("extension_drop_list_temu", JSON.stringify(list));

        var list_tmp = JSON.parse(localStorage.getItem("extension_listtemu"));
        if (list_tmp) {
            localStorage.setItem("extension_listtemu", JSON.stringify(list_tmp?.filter((v2, i2) => list?.some((v1, i1) => v1.product_id === v2.product_id))));
        }
    }, [list]);
    
    useEffect(() => {
        const handleLocalStorageFromExtension = () => {
            setList(JSON.parse(localStorage.getItem("extension_drop_list_temu")));
        };

        window.addEventListener("updateLocalStorageFromExtension", handleLocalStorageFromExtension);
    
        return () => {
            window.removeEventListener("updateLocalStorageFromExtension", handleLocalStorageFromExtension);
        };
    }, []);

    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    const onClickExport = () => {
        var products_selected_export = [];
        products_selected_export = list?.filter((v, i) => products_selected?.includes(v.product_id))?.map((v, i) => {
            return { product_id: v.product_id, title: v.title, images: v.images.join() }
        })
        setProductsSelectedExport(products_selected_export);
        setInitiateDownload(true);
    }

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "shop_name",
            text: t("Shop Name"),
            sort: true,
            editable: false,
        },
        {
            dataField: "product_id",
            text: t("Product ID"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => <Fragment><Link to={row.product_link} target="_blank">{cell}</Link></Fragment>,
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            classes: "al-cursor-pointer",
            style: {
                minWidth: "200px",
                maxWidth: "300px",
            },
            formatter: (cell, row, rowIndex) => (
                <Fragment>
                    <div>
                        <span className={cell?.length < 140 && cell?.length > 40 ? "text-success" : "text-danger"}>
                            Total Title: {cell?.length} <span className="text-info">{countString(cell)}</span>
                        </span>
                    </div>
                    {cell} <i className="fa fa-pencil"></i>
                </Fragment>
            ),
        },
        {
            dataField: "images",
            text: t("Images"),
            sort: true,
            editable: false,
            style: {
                minWidth: "500px",
                maxWidth: "500px",
            },
            formatter: (cell, row, rowIndex) => (
                <Fragment>
                {/* <div style={{ height: "auto", maxHeight: "300px", overflow: "auto" }}> */}
                    <div>
                        <span className="text-success">
                            Total Images: {cell?.length}
                        </span>
                    </div>
                    <SortableList
                        onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, rowIndex)}
                        className="al-thumbsContainer"
                        draggedItemClassName="dragged"
                    >
                        {cell.map((v, i) => {
                            return (
                                <SortableItem key={i}>
                                    <div className="al-thumb">
                                        <div className="al-thumbInner position-relative">
                                            <button
                                                onClick={() => removeImageProduct(row.product_id, i)}
                                                type="button"
                                                className="btn-delete-image"
                                                style={{ zIndex: 9 }}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                            <ReactImageMagnify {...{ imageStyle: { objectFit: "cover" }, imageClassName: "al-product-img al-cursor-move w-100 h-100", smallImage: { alt: "", src: v || require("components/assets/media/misc/image.png"), width: 90, height: 90 }, largeImage: { src: v || require("components/assets/media/misc/image.png"), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 } }} />
                                        </div>
                                    </div>
                                </SortableItem>
                            );
                        })}
                    </SortableList>
                {/* </div> */}
                </Fragment>
            ),
        },
        {
            dataField: "variants_split",
            text: t("Variants"),
            sort: true,
            editable: false,
            style: {
                minWidth: "500px",
                maxWidth: "500px",
            },
            formatter: (cell, row, rowIndex) => (
                // <div style={{ height: "auto", maxHeight: "300px", overflow: "auto" }}>
                //     {
                //         cell.map((v, i) => (
                //             <Fragment key={i}>
                //                 <h6>{v.key}:</h6>{
                //                     v.value.map((v_value, i_value) => (
                //                         <Link to="#" className="btn btn-bg-light btn-active-icon-gray-900 btn-active-text-gray-900 me-2 mb-2" key={i_value}>
                //                             {
                //                                 v.images.length > 0 ?
                //                                     <div className="symbol symbol-50px me-2">
                //                                         <img src={v.images[i_value]} alt="" className="h-75" />
                //                                     </div>
                //                                 : ""
                //                             }
                //                             {v_value}
                //                         </Link>
                //                     ))
                //                 }
                //                 { cell.length !== (i + 1) ? <hr/> : "" }
                //             </Fragment>
                //         ))
                //     }
                // </div>
                <Fragment>
                    {
                        cell ?
                            <div className="row" style={{ height: "auto", maxHeight: "300px", overflow: "auto" }}>
                                <div className="col-9 d-flex flex-column">
                                    {
                                        cell.map((v, i) => (
                                            <Fragment key={i}>
                                                <Link to="#" className="btn btn-bg-light btn-active-icon-gray-900 btn-active-text-gray-900 me-2 mb-2">
                                                    - {
                                                        v?.image ?
                                                            <div className="symbol symbol-50px me-2">
                                                                <img src={v?.image} alt="" className="h-75" />
                                                            </div>
                                                        : ""
                                                    }
                                                    {Object.values(Object.fromEntries(Object.entries(v).filter(([key]) => key !== "image"))).join(" ~ ")} {row.price.currency}<br/>
                                                </Link>
                                            </Fragment>
                                        ))
                                    }
                                </div>
                                <div className="col-3 d-flex flex-column justify-content-start">
                                    <ModalEditInformations onSubmit={handleEditInformations} row={row} column="variants" />
                                </div>
                            </div>
                        : ""
                    }
                </Fragment>
            ),
        },
        {
            dataField: "price",
            text: t("Informations"),
            sort: true,
            editable: false,
            style: {
                minWidth: "300px",
                maxWidth: "300px",
            },
            formatter: (cell, row, rowIndex) => (
                <Fragment>
                    <div className="row">
                        <div className="col-9 d-flex flex-column">
                            <h6>Product SKU:</h6><span className="ms-6">{row.product_sku}</span><hr/>
                            <h6>Price:</h6><span className="ms-6">{row.price.value} {row.price.currency}</span><hr/>
                            <h6>Weight:</h6><span className="ms-6">{row.weight.value} {row.weight.type}</span><hr/>
                            <h6>Dimensions:</h6>
                            <span className="ms-6">Length: {row.dimensions.length} {row.dimensions.unit}</span><br/>
                            <span className="ms-6">Width: {row.dimensions.width} {row.dimensions.unit}</span><br/>
                            <span className="ms-6">Height: {row.dimensions.height} {row.dimensions.unit}</span>
                        </div>
                        <div className="col-3 d-flex flex-column justify-content-center">
                            <ModalEditInformations onSubmit={handleEditInformations} row={row} column="price" />
                        </div>
                    </div>
                </Fragment>
            ),
        },
        {
            dataField: "descriptions",
            text: t("Descriptions"),
            sort: true,
            editable: false,
            style: {
                // minWidth: "700px",
                maxWidth: "700px",
            },
            formatter: (cell, row, rowIndex) => (
                <Fragment>
                    <div className="d-flex gap-4">
                        <div style={{ height: "auto", maxHeight: "300px", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: cell }} />
                        <ModalEditDescriptions onSubmit={handleEditDescriptions} product_id={row.product_id} descriptions={cell} title={row.title} images={row.images} />
                    </div>
                </Fragment>
            ),
        },
        {
            dataField: "note",
            text: t("Note"),
            sort: true,
            formatter: (cell, row, rowIndex) => <Fragment>{cell}</Fragment>,
        },
        {
            dataField: "-",
            text: t("Actions"),
            editable: false,
            formatter: (cell, row, rowIndex) => (
                <>
                    <ModalAddImages onSubmit={handleAddImages} product_id={row.product_id} />
                    <button
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Delete")}
                        onClick={() => deleteProduct(row.product_id)}
                    >
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button>
                </>
            ),
        },
    ];

    const onSortEnd = (oldIndex, newIndex, itemIndex) => {
        setList((prev) => {
            prev[itemIndex].images = arrayMove(prev[itemIndex]?.images || [], oldIndex, newIndex);
            return [...prev];
        });
    };

    const removeImageProduct = (productId, imageRemove) => {
        setList((prev) => {
            prev = prev.map((product) => {
                if (product.product_id === productId) {
                    const images = (product?.images || []).filter((_image, key) => key !== imageRemove);
                    return { ...product, images };
                }
                return { ...product };
            })
            
            return [...prev];
        });
    };

    const deleteProduct = (product_id) => {
        setList((prev) => [...prev?.filter((v, i) => v.product_id !== product_id)]);
    };

    const deleteProducts = () => {
        setList((prev) => [...prev?.filter((v) => !products_selected.includes(v.product_id))]);
        setProductsSelected([]);
    };

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row.product_id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row.product_id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = list.map((v, i) => v.product_id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
            <div className="d-flex">
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) {
                            input.indeterminate = indeterminate;
                            input.checked = checked;
                        }
                    }}
                    onChange={onChange}
                />
                <span style={{ marginLeft: '8px' }}>({products_selected.length})</span>
            </div>
        ),
    };

    const groupButton = () => {
        return (
            <div className="mb-5">
                {
                    expired_at - Date.now() >= 0 ?
                        <Fragment>
                            <ModalChangeText onSubmit={handleChangeText} />
                            <button onClick={() => { onClickChangeTitleByAI() }} className="btn btn-info btn-sm me-1" disabled={products_selected.length <= 0} >
                                <img src={require("components/assets/media/custom/stars-ai-white.svg").default} style={{ "marginTop": "-3px", "marginRight": "5px" }} alt="" />
                                {t("AI Title Optimization")}
                            </button>
                            <ModalTemplate onSubmit={uploadProduct} />
                            <button onClick={onClickExport} className="btn btn-success btn-sm me-1" disabled={products_selected.length <= 0}><i className="ki-outline ki-exit-down"></i>{t("Export")}</button>
                            <CSVLink className="d-none" filename={"temu_" + formatterCreateAt((new Date()).getTime(), "dmy") + ".csv"} data={products_selected_export}>
                                <span ref={btnRef} />
                            </CSVLink>
                        </Fragment>
                    : ""
                }
                <button onClick={deleteProducts} type="button" className="btn btn-danger btn-sm me-1" disabled={products_selected.length <= 0}>
                    {t("Delete Products")}
                </button>
                <ModalEditInformations onSubmit={handleEditInformations} place="groupbutton" row={products_selected} column="all" />
            </div>
        );
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const handleChangeText = (data) => {
        setList((prev) => {
            prev = prev.map((item) => {
                if (!products_selected.includes(item.product_id)) return item;
                let title = item?.title;
                if (data?.textNew && data?.textOld) {
                    title = title?.replace(new RegExp(data?.textOld, "gi"), data?.textNew);
                }

                if (data?.textStart) title = `${data?.textStart} ${title}`;
                if (data?.textEnd) title = `${title} ${data?.textEnd}`;

                return { ...item, title };
            });
            return prev;
        });
    };

    const handleAddImages = (product_id, data) => {
        setList((prev) => {
            prev = prev.map((item, i) => {
                if (item.product_id !== product_id) return item;
                let images = item?.images;
                if (data.length > 0) {
                    images = [...images, ...data];
                }

                return { ...item, images };
            });
            return prev;
        });
    };

    const handleEditDescriptions = (product_id, data) => {
        setList((prev) => {
            prev = prev.map((item, i) => {
                if (item.product_id !== product_id) return item;

                return { ...item, descriptions: data };
            });
            return prev;
        });
    };

    const handleEditInformations = (data) => {
        setList((prev) => {
            prev = prev.map((item, i) => {
                if (!data.product_id.includes(item.product_id)) return item;


                var variants_split = item.variants_split.map((v_variant, i_variant) => {
                    var price = v_variant.price;
                    if (data.variants.exchange_type === "rate") {
                        price = v_variant.price * data.variants.exchange_rate;
                    }
                    if (data.variants.exchange_type === "percent") {
                        price = parseFloat(v_variant.price) + parseFloat(v_variant.price * data.variants.exchange_rate / 100);
                    }
                    if (data.variants.exchange_type === "plus") {
                        price = parseFloat(v_variant.price) + parseFloat(data.variants.exchange_rate);
                    }
                    if (data.variants.exchange_type === "fix") {
                        price = parseFloat(data.variants.exchange_rate);
                    }

                    return { ...v_variant, price: scaleNumber({ number: price }) };
                });

                var price = item.price.value;
                if (data.price.exchange_type === "rate") {
                    price = item.price.value * data.price.exchange_rate;
                }
                if (data.price.exchange_type === "percent") {
                    price = parseFloat(item.price.value) + parseFloat(item.price.value * data.price.exchange_rate / 100);
                }
                if (data.price.exchange_type === "plus") {
                    price = parseFloat(item.price.value) + parseFloat(data.price.exchange_rate);
                }
                if (data.price.exchange_type === "fix") {
                    price = parseFloat(data.price.exchange_rate);
                }

                return { ...item, price: { ...item.price, value: scaleNumber({ number: price }) }, weight: data.weight, dimensions: data.dimensions, variants_split };
            });
            return prev;
        });
    };

    const uploadProduct = ({ templateId, categoryId, otherImage, removePrdImport, numberImage  }) => {
        createProductsByTemplate({
            templateId,
            categoryId,
            type: "drop",
            data: list.reduce((result, item) => {
                if (products_selected.includes(item.product_id)) {
                    const itemAdd = { ...item, main_images: item.images.slice(0, numberImage) };
                    if (otherImage?.length) itemAdd.main_images = [...(itemAdd.main_images || []), ...otherImage];
                    result.push(itemAdd);
                }
                return result;
            }, []),
        }).then((r) => {
            if (removePrdImport && r) {
                setList((prev) => prev.filter((item) => !products_selected.includes(item.product_id)));
                setProductsSelected([]);
            }
        });
    };

    const handleTableSaveCell = (oldValue, newValue, row, column) => {
        row[column.dataField] = newValue;
        setList((prev) => {
            const data = prev.map((product) => {
                if (product.product_id === row.product_id) {
                    product[column.dataField] = newValue;
                }
                return product;
            });
            return [...data];
        });
    };

    const onClickChangeTitleByAI = () => {
        var arr_product = [];
        products_selected.forEach((v, i) => {
            var product_find = list.find((v2, i2) => v2?.product_id === v);
            if (product_find) {
                arr_product.push({ [product_find?.product_id]: product_find?.title });
            }
        });
        changeTitleByAI(arr_product, setList);
    };

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: list.length || 10
        }
    ];

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <ReactTable
                keyField="product_id"
                columns={columns}
                data={list}
                groupButton={groupButton}
                handleTableChange={handleTableChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={list.length}
                alClassName="table-layout-inherit"
                remote={{ search: false, pagination: false, sort: false }}
                isSelectRow={true}
                selectRow={selectRow}
                isCellEdit={true}
                handleBeforeSaveCell={handleTableSaveCell}
                sizePerPageList={sizePerPageList}
            />
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
    ai: state.ai
});
const mapDispatchToProps = { createProductsByTemplate, countString, formatterCreateAt, scaleNumber, changeTitleByAI };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
