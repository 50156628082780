import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "components/Function";
import { getConfigLarkBase, saveConfigLarkBase } from "actions/larkBaseActions";
import Loading from "components/Loading";

function LarkBaseConfig(props) {
    const { t } = useTranslation();
    const { isEmptyObject, saveConfigLarkBase, getConfigLarkBase } = props;
    const { larkConfig, isLoading } = props.larkConfig;

    const [appId, setAppId] = useState("");
    const [appSecret, setAppSecret] = useState("");

    const [spreadsheetId, setSpreadsheetId] = useState("");
    const [sheetId, setSheetId] = useState("");

    useEffect(() => {
        getConfigLarkBase()
    }, [getConfigLarkBase]);

    useEffect(() => {
        if (!isEmptyObject(larkConfig)) {
            setAppId(larkConfig?.appId || '');
            setAppSecret(larkConfig?.appSecret || '');
            setSpreadsheetId(larkConfig?.spreadsheetId || "");
            setSheetId(larkConfig?.sheetId || "");
        }
    }, [isEmptyObject, larkConfig]);

    const onChange = (e) => {
        if (e.target.name === "appId") {
            setAppId(e.target.value);
        }
        if (e.target.name === "appSecret") {
            setAppSecret(e.target.value);
        }

        if (e.target.name === "spreadsheetId") {
            setSpreadsheetId(e.target.value);
        }
        if (e.target.name === "sheetId") {
            setSheetId(e.target.value);
        }
    }

    const onClickSaveEdit = () => {
        saveConfigLarkBase({ appId, appSecret, spreadsheetId, sheetId })
    };

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                    <div className="card-title m-0">
                        <div className="d-flex">
                            <img src="https://www.exabytes.sg/events/wp-content/uploads/2023/06/lark.png" height={50} alt="Lark" />
                        </div>
                    </div>
                </div>
                <div id="kt_account_settings_profile_details" className="collapse show">
                    <div className="card-body border-top p-9">
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("App ID")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <input type="tel" name="appId" className="form-control form-control-lg form-control-solid" placeholder="shtcnmBA*****yGehy8" value={appId} onChange={(e) => { onChange(e) }} />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("App Secret")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <input type="tel" name="appSecret" className="form-control form-control-lg form-control-solid" placeholder="0b**12" value={appSecret} onChange={(e) => { onChange(e) }} />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("Spreadsheet ID")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <input type="tel" name="spreadsheetId" className="form-control form-control-lg form-control-solid" placeholder="shtcnmBA*****yGehy8" value={spreadsheetId} onChange={(e) => { onChange(e) }} />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("Sheet ID")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <input type="tel" name="sheetId" className="form-control form-control-lg form-control-solid" placeholder="0b**12" value={sheetId} onChange={(e) => { onChange(e) }} />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="submit" className="btn btn-primary" onClick={() => { onClickSaveEdit() }}  >{t("Save Changes")}</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

LarkBaseConfig.propTypes = {
    isEmptyObject: PropTypes.func.isRequired,
    saveConfigLarkBase: PropTypes.func.isRequired,
    getConfigLarkBase: PropTypes.func.isRequired,

};
const mapStateToProps = state => ({
    larkConfig: state.larkConfig
});
const mapDispatchToProps = {
    isEmptyObject,
    saveConfigLarkBase,
    getConfigLarkBase,
}

export default connect(mapStateToProps, mapDispatchToProps)(LarkBaseConfig);