import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getNotification, saveNotification, testNotification } from "actions/notificationActions";
import { isEmptyObject } from "components/Function";
import { editUser } from "actions/userActions";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

function Notification(props) {
    const { t } = useTranslation();
    const { isEmptyObject, getNotification, saveNotification, testNotification, editUser } = props;
    var { notification_order, notification_shop, notification_message_warning, notification_return_order, notification_warning_ship, notification_cancel_order } = props.notification;
    const [telegramToken, setTelegramToken] = useState(props?.user?.user?.telegramToken || "");

    const [order_chat_id, setOrderChatId] = useState("");
    const [order_message, setOrderMessage] = useState("");

    const [shop_chat_id, setShopChatId] = useState("");
    const [shop_message, setShopMessage] = useState("");

    const [message_chat_id, setMessageChatId] = useState("");
    const [message_warning, setMessageWarning] = useState("");

    const [return_order_chat_id, setReturnOrderChatId] = useState("");
    const [return_order_message, setReturnOrderMessage] = useState("");

    const [cancel_order_chat_id, setCancelOrderChatId] = useState("");
    const [cancel_order_message, setCancelOrderMessage] = useState("");

    const [warning_ship_chat_id, setWarningShipChatId] = useState("");
    const [warning_ship_message, setWarningShipMessage] = useState("");

    useEffect(() => {
        getNotification();
    }, [getNotification]);

    useEffect(() => {
        if (!isEmptyObject(notification_order)) {
            setOrderChatId(notification_order?.chat_id || '');
            setOrderMessage(notification_order?.message || '');
        }
    }, [isEmptyObject, notification_order]);

    useEffect(() => {
        if (!isEmptyObject(notification_shop)) {
            setShopChatId(notification_shop?.chat_id || '');
            setShopMessage(notification_shop?.message || '');
        }
    }, [isEmptyObject, notification_shop]);

    useEffect(() => {
        if (!isEmptyObject(notification_message_warning)) {
            setMessageChatId(notification_message_warning?.chat_id || '');
            setMessageWarning(notification_message_warning?.message || '');
        }
    }, [isEmptyObject, notification_message_warning]);

    useEffect(() => {
        if (!isEmptyObject(notification_return_order)) {
            setReturnOrderChatId(notification_return_order?.chat_id || '');
            setReturnOrderMessage(notification_return_order?.message || '');
        }
    }, [isEmptyObject, notification_return_order]);

    useEffect(() => {
        if (!isEmptyObject(notification_cancel_order)) {
            setCancelOrderChatId(notification_cancel_order?.chat_id || '');
            setCancelOrderMessage(notification_cancel_order?.message || '');
        }
    }, [isEmptyObject, notification_cancel_order]);

    useEffect(() => {
        if (!isEmptyObject(notification_warning_ship)) {
            setWarningShipChatId(notification_warning_ship?.chat_id || '');
            setWarningShipMessage(notification_warning_ship?.message || '');
        }
    }, [isEmptyObject, notification_warning_ship]);

    const onClickSaveEdit = (id, type, chat_id, message) => {
        if (telegramToken !== "") {
            saveNotification({ _id: id, type: type, chat_id, message });
        } else {
            Swal.fire("Error", "Please update token Telegram!", 'error');
        }

    };

    const onClickTest = (chat_id, message) => {
        if (telegramToken !== "") {
            testNotification({ chat_id, message });
        } else {
            Swal.fire("Error", "Please update token Telegram!", 'error');
        }
    };

    const onClickSaveEditUser = () => {
        editUser({ type: "telegramToken", telegramToken })
    };

    const onChange = (e) => {
        if (e.target.name === "order_chat_id") {
            setOrderChatId(e.target.value);
        }
        if (e.target.name === "order_message") {
            setOrderMessage(e.target.value);
        }
        if (e.target.name === "shop_chat_id") {
            setShopChatId(e.target.value);
        }
        if (e.target.name === "shop_message") {
            setShopMessage(e.target.value);
        }
        if (e.target.name === "message_chat_id") {
            setMessageChatId(e.target.value);
        }
        if (e.target.name === "message_warning") {
            setMessageWarning(e.target.value);
        }
        if (e.target.name === "return_order_chat_id") {
            setReturnOrderChatId(e.target.value);
        }
        if (e.target.name === "return_order_message") {
            setReturnOrderMessage(e.target.value);
        }
        if (e.target.name === "cancel_order_chat_id") {
            setCancelOrderChatId(e.target.value);
        }
        if (e.target.name === "cancel_order_message") {
            setCancelOrderMessage(e.target.value);
        }
        if (e.target.name === "telegramToken") {
            setTelegramToken(e.target.value);
        }
        if (e.target.name === "warning_ship_chat_id") {
            setWarningShipChatId(e.target.value);
        }
        if (e.target.name === "warning_ship_message") {
            setWarningShipMessage(e.target.value);
        }
    }


    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <h1 className="mb-5 mt-5 text-dark text-center">{t("Only allows via Telegram")}</h1>
                <div className="card-body">
                    <div className="row">
                        <div className="col col-lg-6 col-md-12">
                            <h3><Link to="https://youtu.be/TbgBKvhWam0">
                                {t("Instructional videos")}<i className="ki-outline ki-exit-right-corner fs-4 ms-1"></i>
                            </Link><br></br> OR How to use?</h3>
                            Step 1: Find @BotFather and Create new bot<br />
                            Step 2: Copy the token and paste it into the box below and Save<br />
                            Step 3: Create a group on telegram web<br />
                            Step 4: Invite the bot to the group and fill in the corresponding box below with the group ID<br />
                            Step 5: Fill in the appropriate message content<br />
                            Step 6: Click <b>"Test"</b> below to check if the bot is working or not? If the notification works, click Save
                            <br></br>
                            <br></br>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Telegram Access Token")}</span>
                                </label>
                                <input type="text" className="form-control" name="telegramToken" value={telegramToken} placeholder="7298xxxx:AAEG6GCxxxxxxxxxxx" onChange={(e) => { onChange(e) }} />
                            </div>
                            <div className="">
                                <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEditUser() }}  >{t("SAVE")}</button>
                            </div>
                        </div>
                        <div className="col col-lg-6 col-md-12">
                            <h3>Content code</h3>
                            <p className="fs-6">{"{SHOP_NAME} - Shop name"}</p>
                            <p className="fs-6">{"{SHOP_ID} - Shop id"}</p>
                            <p className="fs-6">{"{CEN_SHOP_NAME} - Shop name but censored with ****"}</p>
                            <p className="fs-6">{"{CEN_SHOP_ID} - Shop id but censored with ****"}</p>
                            <p className="fs-6">{"{AMOUNT} - Order total amount"}</p>
                            <p className="fs-6">{"{QUANTITY} - Order items"}</p>
                            <p className="fs-6">{"{ORDER_ID} - Order ID"}</p>
                            <p className="fs-6">{"{NOTE} - Note Shop"}</p>
                            <p className="fs-6">{"{LINE_ITEMS} - Show infomation item"}</p>
                            <p className="fs-6">{"{ADDRESS} - Show address shipping"}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Order Message")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="order_chat_id" value={order_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="order_message" value={order_message} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(order_chat_id, order_message) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_order?._id, "order", order_chat_id, order_message) }}  >{t("SAVE")}</button>
                    </div>
                </div>
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Shop Warning")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="shop_chat_id" value={shop_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="shop_message" value={shop_message} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(shop_chat_id, shop_message) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_shop?._id, "shop", shop_chat_id, shop_message) }}  >{t("SAVE")}</button>
                    </div>
                </div>
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Message Warning")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="message_chat_id" value={message_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="message_warning" value={message_warning} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(message_chat_id, message_warning) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_message_warning?._id, "message_warning", message_chat_id, message_warning) }}  >{t("SAVE")}</button>
                    </div>
                </div>
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Return Order Message")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="return_order_chat_id" value={return_order_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="return_order_message" value={return_order_message} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(return_order_chat_id, return_order_message) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_return_order?._id, "returnorders", return_order_chat_id, return_order_message) }}  >{t("SAVE")}</button>
                    </div>
                </div>
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Cancel Order Message")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="cancel_order_chat_id" value={cancel_order_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="cancel_order_message" value={cancel_order_message} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(cancel_order_chat_id, cancel_order_message) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_cancel_order?._id, "cancelorders", cancel_order_chat_id, cancel_order_message) }}  >{t("SAVE")}</button>
                    </div>
                </div>
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t("Warning Ship")}</h1>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Telegram Channel / Group ID")}</span>
                        </label>
                        <input type="text" className="form-control" name="warning_ship_chat_id" value={warning_ship_chat_id} placeholder="-1001123456789" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("Message")}</span>
                        </label>
                        <textarea rows={10} className="form-control" name="warning_ship_message" value={warning_ship_message} onChange={(e) => { onChange(e) }} ></textarea>
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-info" onClick={() => { onClickTest(warning_ship_chat_id, warning_ship_message) }}  >{t("Test")}</button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEdit(notification_warning_ship?._id, "warning_ship", warning_ship_chat_id, warning_ship_message) }}  >{t("SAVE")}</button>
                    </div>
                </div>
            </div>
        </>
    );
};


Notification.propTypes = {
    isEmptyObject: PropTypes.func.isRequired,
    saveNotification: PropTypes.func.isRequired,
    getNotification: PropTypes.func.isRequired,
    testNotification: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    notification: state.notification,
    user: state.user,
});
const mapDispatchToProps = {
    isEmptyObject,
    saveNotification,
    getNotification,
    testNotification,
    editUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
