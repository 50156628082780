import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ModalUpload({ onSubmit, shops, products_selected }) {
    const [shop, setShop] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [upload_at, setUploadAt] = useState(new Date());
    const [timeSleep, setTimeSleep] = useState(0);
    const { t } = useTranslation();

    const onClose = () => {
        setIsOpen(false);
    };

    const onChangeShop = (e) => {
        var shops_tmp = e.map((v, i) => {
            return v.value;
        });
        setShop(shops_tmp);
    };

    const handleSubmit = () => {
        onSubmit({ shop, upload_at, timeSleep });
        onClose();
    };

    const onChange = (e) => {
        if (e.target.name === "timeSleep") {
            setTimeSleep(e.target.value);
        }
    }

    return (
        <Fragment>
            <button
                className="btn btn-sm btn-info"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                <i className="ki-outline ki-calendar-add fs-2"></i>
                {t("Schedule")} {products_selected}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group mb-5">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Schedule Upload")}</span>
                        </label>
                        <div>
                            <Select
                                isClearable
                                isMulti
                                name="shopSelect"
                                options={shops}
                                className="cotik-select-virtual"
                                classNamePrefix="select"
                                value={shops.filter(({ value }) => shop.includes(value))}
                                onChange={(e) => {
                                    onChangeShop(e);
                                }}
                                placeholder={t("Select a shop ... ")}
                                theme={(theme) => ({
                                    ...theme,
                                    spacing: {
                                        ...theme.spacing,
                                        controlHeight: "100%",
                                        controlWidth: "200px",
                                    },
                                })}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="form-group">
                            <label>Upload At</label>
                            <div className="al-form-input-date">
                                <DatePicker
                                    showTimeSelect
                                    timeIntervals={1}
                                    dateFormat="dd/MM/y HH:mm:ss"
                                    timeFormat="HH:mm"
                                    className="form-control w-100"
                                    selected={upload_at}
                                    onChange={setUploadAt}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Time Next Product (Second)</label>
                            <div className="al-form-input-date">

                                <input type="number" className="form-control w-100" min={0} placeholder="0 second" name="timeSleep" value={timeSleep} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpload);