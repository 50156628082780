import { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "components/Function";
import { editUser } from "actions/userActions";

function Profiledetails(props) {
    const { t } = useTranslation();
    const { isEmptyObject, editUser } = props;

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [birthday, setBirthday] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAdress] = useState("");
    const [vat_id, setVATID] = useState("");
    const [timezone, setTimeZone] = useState("");
    const [auto_delete_product, setAutoDeleteProduct] = useState(false);
    const [time_life_product, setTimeLifeProduct] = useState(999);

    var { user } = props.user;

    useEffect(() => {
        if (!isEmptyObject(user)) {
            setName(user?.name || '');
            setEmail(user?.email || '');
            setBirthday(user?.birthday || '');
            setPhone(user?.phone || '');
            setAdress(user?.address || '');
            setVATID(user?.vat_id || '');
            setTimeZone(user?.timezone || '');
            setAutoDeleteProduct(user?.auto_delete_product || false);
            setTimeLifeProduct(user?.time_life_product || "");
        }
    }, [isEmptyObject, user]);

    const onChange = (e) => {
        if (e.target.name === "name") {
            setName(e.target.value);
        }
        if (e.target.name === "birthday") {
            setBirthday(e.target.value);
        }
        if (e.target.name === "phone") {
            setPhone(e.target.value);
        }
        if (e.target.name === "address") {
            setAdress(e.target.value);
        }
        if (e.target.name === "vat_id") {
            setVATID(e.target.value);
        }
        if (e.target.name === "timezone") {
            setTimeZone(e.target.value);
        }
        if (e.target.name === "auto_delete_product") {
            setAutoDeleteProduct(e.target.value);
        }
        if (e.target.name === "time_life_product") {
            setTimeLifeProduct(e.target.value);
        }
    }

    const onClickSaveEdit = () => {
        editUser({ type: "profile_details", name, birthday, phone, address, vat_id, timezone, auto_delete_product, time_life_product })
    };

    return (
        <Fragment>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                    <div className="card-title m-0">
                        <h3 className="fw-bold m-0">{t("Profile Details")}</h3>
                    </div>
                </div>
                <div id="kt_account_settings_profile_details" className="collapse show">
                    <div className="card-body border-top p-9">
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">{t("Full Name")}</label>
                            <div className="col-lg-10">
                                <div className="row">
                                    <div className="fv-row fv-plugins-icon-container">
                                        <input type="text" name="name" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder={t("Name")} value={name} onChange={(e) => { onChange(e) }} />
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">{t("Email")}</label>
                            <div className="col-lg-10 fv-row">
                                <input type="email" name="email" className="form-control form-control-lg form-control-solid" placeholder="email@cotik.app" value={email} disabled={true} />
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("Contact Phone")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <input type="tel" name="phone" className="form-control form-control-lg form-control-solid" placeholder="+1044 3....." value={phone} onChange={(e) => { onChange(e) }} />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("Birthday")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <input type="tel" name="birthday" className="form-control form-control-lg form-control-solid" placeholder="mm/dd/yyyy" value={birthday} onChange={(e) => { onChange(e) }} />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("Full Address")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <input type="text" name="address" className="form-control form-control-lg form-control-solid" placeholder="2121 Airport Fwy, Bedford, TX 76021, US........" value={address} onChange={(e) => { onChange(e) }} />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("VAT ID")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <input type="tel" name="vat_id" className="form-control form-control-lg form-control-solid" placeholder="1548****" value={vat_id} onChange={(e) => { onChange(e) }} />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">{t("Time Zone")}</label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <select name="timezone" value={timezone} className="form-select form-select-solid form-select-lg fw-semibold" onChange={(e) => { onChange(e) }} >
                                    <option value="" data-value="">
                                        Select a default time zone ...{" "}
                                    </option>
                                    <option value="Etc/GMT+12" data-value="-12">
                                        (GMT-12:00) International Date Line West
                                    </option>
                                    <option value="Pacific/Midway" data-value="-11">
                                        (GMT-11:00) Midway Island, Samoa
                                    </option>
                                    <option value="Pacific/Honolulu" data-value="-10">
                                        (GMT-10:00) Hawaii
                                    </option>
                                    <option value="US/Alaska" data-value="-9">
                                        (GMT-09:00) Alaska
                                    </option>
                                    <option value="America/Los_Angeles" data-value="-8">
                                        (GMT-08:00) Pacific Time (US & Canada)
                                    </option>
                                    <option value="America/Tijuana" data-value="-8">
                                        (GMT-08:00) Tijuana, Baja California
                                    </option>
                                    <option value="US/Arizona" data-value="-7">
                                        (GMT-07:00) Arizona
                                    </option>
                                    <option value="America/Chihuahua" data-value="-7">
                                        (GMT-07:00) Chihuahua, La Paz, Mazatlan
                                    </option>
                                    <option value="US/Mountain" data-value="-7">
                                        (GMT-07:00) Mountain Time (US & Canada)
                                    </option>
                                    <option value="America/Managua" data-value="-6">
                                        (GMT-06:00) Central America
                                    </option>
                                    <option value="US/Central" data-value="-6">
                                        (GMT-06:00) Central Time (US & Canada)
                                    </option>
                                    <option value="America/Mexico_City" data-value="-6">
                                        (GMT-06:00) Guadalajara, Mexico City, Monterrey
                                    </option>
                                    <option value="Canada/Saskatchewan" data-value="-6">
                                        (GMT-06:00) Saskatchewan
                                    </option>
                                    <option value="America/Bogota" data-value="-5">
                                        (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                                    </option>
                                    <option value="US/Eastern" data-value="-5">
                                        (GMT-05:00) Eastern Time (US & Canada)
                                    </option>
                                    <option value="US/East-Indiana" data-value="-5">
                                        (GMT-05:00) Indiana (East)
                                    </option>
                                    <option value="Canada/Atlantic" data-value="-4">
                                        (GMT-04:00) Atlantic Time (Canada)
                                    </option>
                                    <option value="America/Caracas" data-value="-4">
                                        (GMT-04:00) Caracas, La Paz
                                    </option>
                                    <option value="America/Manaus" data-value="-4">
                                        (GMT-04:00) Manaus
                                    </option>
                                    <option value="America/Santiago" data-value="-4">
                                        (GMT-04:00) Santiago
                                    </option>
                                    <option value="Canada/Newfoundland" data-value="-3.5">
                                        (GMT-03:30) Newfoundland
                                    </option>
                                    <option value="America/Sao_Paulo" data-value="-3">
                                        (GMT-03:00) Brasilia
                                    </option>
                                    <option value="America/Argentina/Buenos_Aires" data-value="-3">
                                        (GMT-03:00) Buenos Aires, Georgetown
                                    </option>
                                    <option value="America/Godthab" data-value="-3">
                                        (GMT-03:00) Greenland
                                    </option>
                                    <option value="America/Montevideo" data-value="-3">
                                        (GMT-03:00) Montevideo
                                    </option>
                                    <option value="America/Noronha" data-value="-2">
                                        (GMT-02:00) Mid-Atlantic
                                    </option>
                                    <option value="Atlantic/Cape_Verde" data-value="-1">
                                        (GMT-01:00) Cape Verde Is.
                                    </option>
                                    <option value="Atlantic/Azores" data-value="-1">
                                        (GMT-01:00) Azores
                                    </option>
                                    <option value="Africa/Casablanca" data-value="0">
                                        (GMT+00:00) Casablanca, Monrovia, Reykjavik
                                    </option>
                                    <option value="Etc/Greenwich" data-value="0">
                                        (GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London
                                    </option>
                                    <option value="Europe/Amsterdam" data-value="1">
                                        (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna
                                    </option>
                                    <option value="Europe/Belgrade" data-value="1">
                                        (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague
                                    </option>
                                    <option value="Europe/Brussels" data-value="1">
                                        (GMT+01:00) Brussels, Copenhagen, Madrid, Paris
                                    </option>
                                    <option value="Europe/Sarajevo" data-value="1">
                                        (GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb
                                    </option>
                                    <option value="Africa/Lagos" data-value="1">
                                        (GMT+01:00) West Central Africa
                                    </option>
                                    <option value="Asia/Amman" data-value="2">
                                        (GMT+02:00) Amman
                                    </option>
                                    <option value="Europe/Athens" data-value="2">
                                        (GMT+02:00) Athens, Bucharest, Istanbul
                                    </option>
                                    <option value="Asia/Beirut" data-value="2">
                                        (GMT+02:00) Beirut
                                    </option>
                                    <option value="Africa/Cairo" data-value="2">
                                        (GMT+02:00) Cairo
                                    </option>
                                    <option value="Africa/Harare" data-value="2">
                                        (GMT+02:00) Harare, Pretoria
                                    </option>
                                    <option value="Europe/Helsinki" data-value="2">
                                        (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius
                                    </option>
                                    <option value="Asia/Jerusalem" data-value="2">
                                        (GMT+02:00) Jerusalem
                                    </option>
                                    <option value="Europe/Minsk" data-value="2">
                                        (GMT+02:00) Minsk
                                    </option>
                                    <option value="Africa/Windhoek" data-value="2">
                                        (GMT+02:00) Windhoek
                                    </option>
                                    <option value="Asia/Kuwait" data-value="3">
                                        (GMT+03:00) Kuwait, Riyadh, Baghdad
                                    </option>
                                    <option value="Europe/Moscow" data-value="3">
                                        (GMT+03:00) Moscow, St. Petersburg, Volgograd
                                    </option>
                                    <option value="Africa/Nairobi" data-value="3">
                                        (GMT+03:00) Nairobi
                                    </option>
                                    <option value="Asia/Tbilisi" data-value="3">
                                        (GMT+03:00) Tbilisi
                                    </option>
                                    <option value="Asia/Tehran" data-value="3.5">
                                        (GMT+03:30) Tehran
                                    </option>
                                    <option value="Asia/Muscat" data-value="4">
                                        (GMT+04:00) Abu Dhabi, Muscat
                                    </option>
                                    <option value="Asia/Baku" data-value="4">
                                        (GMT+04:00) Baku
                                    </option>
                                    <option value="Asia/Yerevan" data-value="4">
                                        (GMT+04:00) Yerevan
                                    </option>
                                    <option value="Asia/Kabul" data-value="4.5">
                                        (GMT+04:30) Kabul
                                    </option>
                                    <option value="Asia/Yekaterinburg" data-value="5">
                                        (GMT+05:00) Yekaterinburg
                                    </option>
                                    <option value="Asia/Karachi" data-value="5">
                                        (GMT+05:00) Islamabad, Karachi, Tashkent
                                    </option>
                                    <option value="Asia/Calcutta" data-value="5.5">
                                        (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi
                                    </option>
                                    <option value="Asia/Calcutta" data-value="5.5">
                                        (GMT+05:30) Sri Jayawardenapura
                                    </option>
                                    <option value="Asia/Katmandu" data-value="5.75">
                                        (GMT+05:45) Kathmandu
                                    </option>
                                    <option value="Asia/Almaty" data-value="6">
                                        (GMT+06:00) Almaty, Novosibirsk
                                    </option>
                                    <option value="Asia/Dhaka" data-value="6">
                                        (GMT+06:00) Astana, Dhaka
                                    </option>
                                    <option value="Asia/Rangoon" data-value="6.5">
                                        (GMT+06:30) Yangon (Rangoon)
                                    </option>
                                    <option value="Asia/Bangkok" data-value="7">
                                        (GMT+07:00) Bangkok, Hanoi, Jakarta
                                    </option>
                                    <option value="Asia/Krasnoyarsk" data-value="7">
                                        (GMT+07:00) Krasnoyarsk
                                    </option>
                                    <option value="Asia/Hong_Kong" data-value="8">
                                        (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi
                                    </option>
                                    <option value="Asia/Kuala_Lumpur" data-value="8">
                                        (GMT+08:00) Kuala Lumpur, Singapore
                                    </option>
                                    <option value="Asia/Irkutsk" data-value="8">
                                        (GMT+08:00) Irkutsk, Ulaan Bataar
                                    </option>
                                    <option value="Australia/Perth" data-value="8">
                                        (GMT+08:00) Perth
                                    </option>
                                    <option value="Asia/Taipei" data-value="8">
                                        (GMT+08:00) Taipei
                                    </option>
                                    <option value="Asia/Tokyo" data-value="9">
                                        (GMT+09:00) Osaka, Sapporo, Tokyo
                                    </option>
                                    <option value="Asia/Seoul" data-value="9">
                                        (GMT+09:00) Seoul
                                    </option>
                                    <option value="Asia/Yakutsk" data-value="9">
                                        (GMT+09:00) Yakutsk
                                    </option>
                                    <option value="Australia/Adelaide" data-value="9.5">
                                        (GMT+09:30) Adelaide
                                    </option>
                                    <option value="Australia/Darwin" data-value="9.5">
                                        (GMT+09:30) Darwin
                                    </option>
                                    <option value="Australia/Brisbane" data-value="10">
                                        (GMT+10:00) Brisbane
                                    </option>
                                    <option value="Australia/Canberra" data-value="10">
                                        (GMT+10:00) Canberra, Melbourne, Sydney
                                    </option>
                                    <option value="Australia/Hobart" data-value="10">
                                        (GMT+10:00) Hobart
                                    </option>
                                    <option value="Pacific/Guam" data-value="10">
                                        (GMT+10:00) Guam, Port Moresby
                                    </option>
                                    <option value="Asia/Vladivostok" data-value="10">
                                        (GMT+10:00) Vladivostok
                                    </option>
                                    <option value="Asia/Magadan" data-value="11">
                                        (GMT+11:00) Magadan, Solomon Is., New Caledonia
                                    </option>
                                    <option value="Pacific/Auckland" data-value="12">
                                        (GMT+12:00) Auckland, Wellington
                                    </option>
                                    <option value="Pacific/Fiji" data-value="12">
                                        (GMT+12:00) Fiji, Kamchatka, Marshall Is.
                                    </option>
                                    <option value="Pacific/Tongatapu" data-value="13">
                                        (GMT+13:00) Nuku'alofa
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("Auto Delete Product")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <select name="auto_delete_product" value={auto_delete_product} className="form-select form-select-solid form-select-lg fw-semibold" onChange={(e) => { onChange(e) }} >
                                    <option value="false" data-value="false">
                                        NOT USING
                                    </option>
                                    <option value="true" data-value="-12">
                                        USING
                                    </option>
                                </select>
                            </div>
                        </div>
                        {auto_delete_product && <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                                <span className="">{t("Time Life Product")}</span>
                            </label>
                            <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                <div className="input-group border">
                                    <input type="number" className="form-control" value={time_life_product} placeholder="0" name="time_life_product" onChange={(e) => { onChange(e) }} />
                                    <span className="btn btn-input">
                                        days
                                    </span>
                                </div>
                            </div>
                        </div>}                        
                    </div>

                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="submit" className="btn btn-primary" onClick={() => { onClickSaveEdit() }}  >{t("Save Changes")}</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Profiledetails.propTypes = {
    isEmptyObject: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,

};
const mapStateToProps = state => ({
    user: state.user
});
const mapDispatchToProps = {
    isEmptyObject,
    editUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profiledetails);