import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getListOrders = (page = 0, sizeperpage = 0, search = "", filter = "", filter2 = "", filter3 = "", filter4 = "", filter5 = "", filter6 = "", filter7 = "") => (dispatch, getState) => {
	dispatch({ type: 'LOADING_ORDER', payload: true });
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	var param_filter = "";
	if (filter !== "") {
		param_filter = `&filter=${filter}`;
	}
	var param_filter2 = "";
	if (filter2 !== "") {
		param_filter2 = `&filter2=${filter2}`;
	}
	var param_filter3 = "";
	if (filter3 !== "") {
		param_filter3 = `&filter3=${filter3}`;
	}
	var param_filter4 = "";
	if (filter4 !== "") {
		param_filter4 = `&filter4=${filter4}`;
	}
	var param_filter5 = "";
	if (filter5 !== "") {
		param_filter5 = `&filter5=${filter5}`;
	}
	var param_filter6 = "";
	if (filter6 !== "") {
		param_filter6 = `&filter6=${filter6}`;
	}
	var param_filter7 = "";
	if (filter7 !== "") {
		param_filter7 = `&filter7=${filter7}`;
	}
	axios.get(`/api/order/list?page=${page}&sizeperpage=${sizeperpage}${param_search}${param_filter}${param_filter2}${param_filter3}${param_filter4}${param_filter5}${param_filter6}${param_filter7}`, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_ORDER', payload: false });
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_ORDER',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_ORDER', payload: false });
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

const saveEditOrder = (data, closemodal = () => { }) => (dispatch, getState) => {
	var body = {
		_id: data?._id,
		keywords: data?.keywords,
		type: data?.type,
		printer: data?.printer,
		design_fee: data?.design_fee,
		mockup: data?.mockup,
		design: data?.design,
		shipping_label: data?.shipping_label,
		order_status: data?.order_status,
		tracking_number: data?.tracking_number,
		fulfillment_order_id: data?.fulfillment_order_id,
	};
	// Swal.fire({
	// 	title: t("Are you sure?"),
	// 	text: t("Are you sure to edit order?"),
	// 	icon: 'warning',
	// 	showCancelButton: true,
	// 	confirmButtonColor: '#3085d6',
	// 	cancelButtonColor: '#d33',
	// 	confirmButtonText: t("Sure"),
	// 	cancelButtonText: t("Cancel")
	// }).then((result) => {
	// 	if (result.value) {
	dispatch({ type: 'LOADING_ORDER', payload: true });
	if (data?.tracking_number && data?.tracking_number !== "") {
		axios.post('/api/order/tracking-number', body, tokenConfig(getState)).then(res => {
			dispatch({ type: 'LOADING_ORDER', payload: false });
			if (res.data.status === 200) {
				closemodal();
				dispatch(getListOrders(data.page, data.sizePerPage, data.searchText, data.filter_printer, data.filter_shop_code, data.filter_status, data.filter_work_status, data.filter_staff, data.dateStart, data.dateEnd));
				// Swal.fire(t("Success"), t(res.data.message), 'success');
			} else {
				Swal.fire(t("Error"), t(res.data.message), 'error');
			}
		}).catch(err => {
			dispatch({ type: 'LOADING_ORDER', payload: false });
			Swal.fire(t("Error"), t("An error occurred!"), 'error');
		});
	} else {
		axios.post('/api/order/edit', body, tokenConfig(getState)).then(res => {
			dispatch({ type: 'LOADING_ORDER', payload: false });
			if (res.data.status === 200) {
				closemodal();
				dispatch(getListOrders(data.page, data.sizePerPage, data.searchText, data.filter_printer, data.filter_shop_code, data.filter_status, data.filter_work_status, data.filter_staff, data.dateStart, data.dateEnd));
				// Swal.fire(t("Success"), t(res.data.message), 'success');
			} else {
				Swal.fire(t("Error"), t(res.data.message), 'error');
			}
		}).catch(err => {
			dispatch({ type: 'LOADING_ORDER', payload: false });
			Swal.fire(t("Error"), t("An error occurred!"), 'error');
		});
	}

	// 	}
	// })
};

const saveEditOrderMember = (data, closemodal = () => { }) => (dispatch, getState) => {
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to edit member order?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_ORDER', payload: true });
			axios.post('/api/order/edit-member', data, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_ORDER', payload: false });
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListOrders(data.page, data.sizePerPage, data.searchText, data.filter_printer, data.filter_shop_code, data.filter_status, data.filter_work_status, data.filter_staff, data.dateStart, data.dateEnd));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_ORDER', payload: false });
				Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const sendToPrinter = (data) => (dispatch, getState) => {
	var body = {
		_id: data?._id ? data?._id.split(",") : []
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to send to printer?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_ORDER', payload: true });
			axios.post('/api/order/send-to-printer', body, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_ORDER', payload: false });
				if (res.data.status === 200) {
					data.setOrdersSelected([]);
					dispatch(getListOrders(data.page, data.sizePerPage, data.searchText, data.filter_printer, data.filter_shop_code, data.filter_status, data.filter_work_status, data.filter_staff, data.dateStart, data.dateEnd));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_ORDER', payload: false });
				data.setOrdersSelected([]);
				dispatch(getListOrders(data.page, data.sizePerPage, data.searchText, data.filter_printer, data.filter_shop_code, data.filter_status, data.filter_work_status, data.filter_staff, data.dateStart, data.dateEnd));
				// Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const cancelToPrinter = (data) => (dispatch, getState) => {
	var body = {
		_id: data?._id ? data?._id.split(",") : []
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to cancel to printer?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_ORDER', payload: true });
			axios.post('/api/order/cancel-to-printer', body, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_ORDER', payload: false });
				if (res.data.status === 200) {
					dispatch(getListOrders(data.page, data.sizePerPage, data.searchText, data.filter_printer, data.filter_shop_code, data.filter_status, data.filter_work_status, data.filter_staff, data.dateStart, data.dateEnd));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_ORDER', payload: false });
				Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const exportOrder = (search = "", filter = "", filter2 = "", filter3 = "", filter4 = "", filter5 = "", filter6 = "", filter7 = "") => (dispatch, getState) => {
	dispatch({ type: 'LOADING_ORDER', payload: true });
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	var param_filter = "";
	if (filter !== "") {
		param_filter = `&filter=${filter}`;
	}
	var param_filter2 = "";
	if (filter2 !== "") {
		param_filter2 = `&filter2=${filter2}`;
	}
	var param_filter3 = "";
	if (filter3 !== "") {
		param_filter3 = `&filter3=${filter3}`;
	}
	var param_filter4 = "";
	if (filter4 !== "") {
		param_filter4 = `&filter4=${filter4}`;
	}
	var param_filter5 = "";
	if (filter5 !== "") {
		param_filter5 = `&filter5=${filter5}`;
	}
	var param_filter6 = "";
	if (filter6 !== "") {
		param_filter6 = `&filter6=${filter6}`;
	}
	var param_filter7 = "";
	if (filter7 !== "") {
		param_filter7 = `&filter7=${filter7}`;
	}
	axios.get(`/api/order/export?${param_search}${param_filter}${param_filter2}${param_filter3}${param_filter4}${param_filter5}${param_filter6}${param_filter7}`, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_ORDER', payload: false });
		if (res.data.status === 200) {
			dispatch({
				type: 'EXPORT_ORDER',
				payload: res.data.data
			});
		} else {
			Swal.fire("Error", res.data.message, 'error');
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_ORDER', payload: false });
		Swal.fire("Error", "An error occurred!", 'error');
	});
};

const importTracking = (data, closemodal) => (dispatch, getState) => {
	Swal.fire({
		title: "Are you sure?",
		text: "Are you sure to import tracking?",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: "Sure",
		cancelButtonText: "Cancel"
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_ORDER', payload: true});
			var options = {
				method: "POST",
				url: "/api/order/import-tracking",
				headers: {
					"Content-Type": "multipart/form-data;",
					"al-token": getState().user.token
				},
				data: data.formData
			};
			axios.request(options).then(function (res) {
				dispatch({type: 'LOADING_ORDER', payload: false});
				if (res.data.status === 200) {
					closemodal();
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_ORDER', payload: false});
				Swal.fire("Error", "An error occurred!", 'error');
			});
		}
	})
};

export {
	getListOrders,
	saveEditOrder,
	sendToPrinter,
	cancelToPrinter,
	saveEditOrderMember,
	exportOrder,
	importTracking,
};
