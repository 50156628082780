import { useEffect, useState } from "react";
import { connect } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import SelectBox from "components/elements/selectBox";
import NeedAction from "./needAction";
import { getShops } from "actions/shopActions";
import { getStaffs } from "actions/teamActions";
import Total from "./total";
// import Chart from "./chart";
import OnHold from "./onHold";
import { getTotalOrder, getDataChart, getOnHold } from "actions/analyticActions";
import { formatterCreateAt, convertTZ, getDaysArray } from "components/Function";
import { ListDateType } from "helper/constant";
import Loading from "components/Loading";

const Analytic = ({ getTotalOrder, getDataChart, getOnHold, formatterCreateAt, shop, getShops, getStaffs }) => {
    const [filter_shop_code, setFilterShopCode] = useState("");
    const [data, setData] = useState({});
    // const [dataChart, setDataChart] = useState({});
    const [dataOnHold, setDataOnHold] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [filter, setFilter] = useState({});
    // const [dates, setDates] = useState([]);
    const [dateType, setDateType] = useState("today");
    const [loading, setLoading] = useState(false);
    const { list } = shop;
    const list_shop = list?.map((v, i) => {
        return { label: `${v?.name} (${v?.note}) - ${v?.region} - ${v?.code}`, value: v?._id };
    });

    const onChangeFilterShopCode = async (e) => {
        setFilter((prev) => ({ ...prev, shop: e?.value }));
    };

    useEffect(() => {
        if (Object.keys(filter)?.length) {
            setLoading(true);
            const total = getTotalOrder({ filter });
            // const chart = getDataChart({ filter });
            // const onHold = getOnHold({ filter });

            Promise.all([total]).then(([t, o]) => {
                setData({ ...(t?.data || {}) });
                // setDataChart(c?.data || {});
                // setDataOnHold(o?.data || {});
                setLoading(false);
            });
        }
    }, [getTotalOrder, getDataChart, getOnHold, filter, filter_shop_code]);

    useEffect(() => {
        getShops({ page: 1, sizeperpage: 9999 });
    }, [getShops]);

    
    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    // useEffect(() => {
    //     if (filter?.dateStart && filter?.dateEnd) {
    //         setDates(getDaysArray(filter.dateStart, filter.dateEnd));
    //     }
    // }, [filter]);

    useEffect(() => {
        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const handleEventDate = (start, end) => {
        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    const reloadOnHold = (shop) => {
        return getOnHold({ filter: { shop } }).then((o) => setDataOnHold(o?.data || {}));
    };

    return (
        <div className="col-xl-12 mb-10">
            <Loading isLoading={loading} />
            <NeedAction data={data} />
            <div className="d-flex gap-4 mt-4 flex-column flex-md-row">
                <Select
                    name="dateType"
                    options={ListDateType.filter((v) => v.value)}
                    className="al-select"
                    classNamePrefix="select"
                    value={ListDateType.filter(({ value }) => value === dateType)}
                    onChange={(e) => {
                        setDateType(e?.value || "");
                    }}
                    placeholder="Select a date ... "
                />
                <Select
                    isClearable
                    name="shop_code"
                    options={list_shop}
                    className="al-select"
                    classNamePrefix="select"
                    value={list_shop.filter(({ value }) => value === filter_shop_code)}
                    onChange={(e) => {
                        onChangeFilterShopCode(e);
                    }}
                    placeholder="Select a shop code ... "
                />
                <SelectBox
                    options={staffs}
                    name="filter_staff"
                    value={filter.staff || ""}
                    onChange={(data) => {
                        setFilter((prev) => ({
                            ...prev,
                            staff: data.value || "",
                        }));
                    }}
                />
                {dateType === "custom" && filter?.dateStart && filter?.dateEnd && (
                    <div>
                        <DateRangePicker
                            onCallback={handleEventDate}
                            initialSettings={{
                                startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                showDropdowns: true,
                                locale: { format: "DD/MM/YYYY" },
                            }}
                        >
                            <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                        </DateRangePicker>
                    </div>
                )}
            </div>
            <Total data={data} />
            {/* <Chart data={dataChart} dates={dates} /> */}
            {/* <OnHold data={dataOnHold} /> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    shop: state.shop,
});
const mapDispatchToProps = { getTotalOrder, getDataChart, getOnHold, formatterCreateAt, getShops, getStaffs };

export default connect(mapStateToProps, mapDispatchToProps)(Analytic);
