import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { getListOrders } from "./orderActions";
import { t } from "i18next";

const getBuyLabel = (data, closemodal) => (dispatch, getState) => {
	var body = {
		_id: data?._id ? data?._id.split(",") : [],
		type: data?.type,
		dimension_length: data?.dimension_length,
		dimension_width: data?.dimension_width,
		dimension_height: data?.dimension_height,
		dimension_unit: data?.dimension_unit,
		weight_value: data?.weight_value,
		weight_unit: data?.weight_unit,
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to buy label?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_FULFILLMENT', payload: true});
			axios.post('/api/fulfillment/buy-label', body, tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_FULFILLMENT', payload: false});
				if (res.data.status === 200) {
					if (data?.setOrdersSelected) {
						data?.setOrdersSelected([]);
					}
                    closemodal();
					dispatch(getListOrders(data.page, data.sizePerPage, data.searchText, data.filter_printer, data.filter_shop_code, data.filter_status, data.filter_work_status, data.filter_staff, data.dateStart, data.dateEnd));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
                    Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_FULFILLMENT', payload: false});
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const getProductDimensionWeightFromOrder = (data) => (dispatch, getState) => {
	var body = {
		_id: data?._id ? data?._id.split(",") : [],
	};
	dispatch({ type: 'LOADING_FULFILLMENT', payload: true });
	axios.post('/api/fulfillment/product-dimension-weight-from-order', body, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_FULFILLMENT', payload: false });
		if (res.data.status === 200) {

			data.setDimensionLength(res.data.data?.dimensions?.length || 0);
			data.setDimensionWidth(res.data.data?.dimensions?.width || 0);
			data.setDimensionHeight(res.data.data?.dimensions?.height || 0);
			data.setDimensionUnit(res.data.data?.dimensions?.unit || "");
			data.setWeightValue(res.data.data?.weight?.value || 0);
			data.setWeightUnit(res.data.data?.weight?.type || "");
			// Swal.fire(t("Success"), t(res.data.message), 'success');
		} else {
			// Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_FULFILLMENT', payload: false });
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

export {
    getBuyLabel,
    getProductDimensionWeightFromOrder
};
