import { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getTikTokFirstSales, uploadTemplateByLink } from "actions/spytoolActions";
import { ReactTable } from "components/Table";
import { ListDateType } from "helper/constant";
// import { getProductTemplate } from "actions/productTemplateAction";
import { createProductsByTemplate } from "actions/productActions";
import { ModalChangeText, ModalTemplate } from "./modal";
import ReactImageMagnify from 'react-image-magnify';
import {
    formatterCreateAt,
    convertTZ,
    sleep,
    countString,
} from "components/Function";
import Loading from "components/Loading";
// import _ from "lodash";
// import Swal from "sweetalert2";
// import ModalImport from "./modalImport";
import { CSVLink } from "react-csv";
import SelectBox from "components/elements/selectBox";

const FirstSales = ({
    getTikTokFirstSales,
    product_first_sale,
    formatterCreateAt,
    // productTemplate,
    // getProductTemplate,
    // uploadTemplateByLink,
    countString,
    createProductsByTemplate
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [dateType, setDateType] = useState("");
    const [products_selected, setProductsSelected] = useState([]);
    const [filter, setFilter] = useState({});
    const [dataExport, setDataExport] = useState([]);
    const [initiateDownload, setInitiateDownload] = useState(false);
    const btnRef = useRef(null);


    const { t } = useTranslation();

    const { list: list_data, totalsize, isLoading } = product_first_sale;

    const [list, setList] = useState(list_data);

    // const { list: listTemplate } = productTemplate;

    // const list_template = listTemplate.map((v, i) => {
    //     return { "value": v._id, "label": v.title }
    // })

    const handleTableSaveCell = (oldValue, newValue, row, column) => {
        row[column.dataField] = newValue;
        setList((prev) => {
            const data = prev.map((product) => {
                if (product.id === row.id) {
                    return { ...product, [column.dataField]: newValue };
                }
                return { ...product };
            });
            return [...data];
        });
    };

    const list_country = [
        { "value": "US", "label": "United States" },
        { "value": "GB", "label": "United Kingdom" },
        { "value": "ID", "label": "Indonesia" },
        { "value": "TH", "label": "Thailand" },
        { "value": "PH", "label": "Philipines" },
        { "value": "MY", "label": "Malaysia" },
        { "value": "SG", "label": "Singapore" },
    ]

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "20",
            value: 20,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    useEffect(() => {
        if (Object.keys(filter).length) {
            getTikTokFirstSales({ page, sizeperpage: sizePerPage, search: searchText, filter });
        }
    }, [getTikTokFirstSales, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setList(list_data)
    }, [list_data]);

    // useEffect(() => {
    //     getProductTemplate()
    // }, [getProductTemplate]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    useEffect(() => {
        setPage(1);
        setSizePerPage(50);

        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date);
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date);
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "image",
            text: t("Image"),
            sort: true,
            editable: false,
            formatter: (cell, row) => (
                <>
                    <div className="symbol symbol-50px">
                        <Link to={"https://shop.tiktok.com/view/product/" + row.id || "#"} target="_blank"><ReactImageMagnify {...{ smallImage: { alt: "", width: 200, height: 200, src: cell || require("components/assets/media/misc/image.png") }, largeImage: { src: cell || require("components/assets/media/misc/image.png"), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} /></Link>
                    </div>
                </>
            ),
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            formatter: (cell, row) => <Fragment>
                <div className="d-flex flex-column">
                    <span className={cell?.length < 140 && cell?.length > 40 ? "text-success" : "text-danger"}>
                        Total Title: {cell?.length} <span className="text-info">{countString(cell)}</span>
                    </span>
                    <div className="h4">
                        {cell} <i className="fa fa-pencil"></i>
                    </div>
                    <div className="h5">
                        ID: <Link to={"https://shop.tiktok.com/view/product/" + row?.id} target="_blank">{row?.id}</Link>
                    </div>
                    <div>
                        <i>Started at: <b>{row?.first_time}</b></i>
                    </div>
                    <div>
                        <i>Yesterday Sold: <b>{row?.yesterday_sold} - {row?.yesterday_sales}</b></i>
                    </div>
                    <div>
                        <i>Total Sold: <b>{row?.sold_count} - {row?.total_sales}</b></i>
                    </div>
                    {row?.status === false && <div>
                        <b className="text-danger">Removed</b>
                    </div>}
                </div>
            </Fragment>,
        },
        {
            dataField: "shop",
            text: t("Shop"),
            sort: true,
            editable: false,
            formatter: (cell, row) =>
                <Fragment>
                    <img src={cell?.shop_logo ? cell?.shop_logo : require("components/assets/media/misc/image.png")} alt="" height={50} />
                    <div>
                        <i><b>{cell?.shop_name}</b></i>
                    </div>
                    <div>
                        {cell?.shop_id}
                    </div>
                    {cell?.shop_status === false && <div>
                        <b className="text-danger">Closed</b>
                    </div>}
                </Fragment>
        },
        {
            dataField: "first_time",
            text: t("Time Created"),
            sort: true,
            editable: false,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };


    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(50);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    // const handleSubmitUpload = async (data) => {
    //     let data_uniqe = _.uniqBy(data, "id");
    //     Promise.all(data_uniqe?.map(async (obj, iv) => {
    //         await sleep(1000 * iv)
    //         return new Promise(async (resolve2, reject2) => {
    //             await uploadTemplateByLink(obj).then((data_) => {
    //                 resolve2('ok');
    //             })
    //                 .catch((err) => {
    //                     resolve2('ok');
    //                 });
    //             resolve2('ok');
    //         });
    //     })).then(async (dat) => {
    //         setProductsSelected([])
    //         return Swal.fire(t("Success"), t("Upload Success!"), "success");
    //     })
    // };

    const onClickExportData = async () => {
        let save_data_export = [];
        save_data_export = list?.filter((v, i) => products_selected?.includes(v._id))?.map((v, i) => {
            return { title: v.title, image1: v?.product_cover ? "https://t-img.picturehaven.net/product/" + v?.product_cover : "", image2: v?.main_images?.[1] || "", image3: v?.main_images?.[2] || "", image4: v?.main_images?.[3] || "", image5: v?.main_images?.[4] || "", image6: v?.main_images?.[5] || "", image7: v?.main_images?.[6] || "", image8: v?.main_images?.[7] || "", image9: v?.main_images?.[8] || "" }
        })
        setDataExport(save_data_export);
        setInitiateDownload(true);
        await sleep(2500)
    }

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row._id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row._id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
        if (products_selected_tmp.length !== 0) {
            // setLoadingSync(false);
        }
        if (products_selected_tmp.length === 0) {
            // setLoadingSync(true);
        }
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = row.map((v, i) => v._id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
        if (products_selected_tmp.length !== 0) {
            // setLoadingSync(false);
        }
        if (products_selected_tmp.length === 0) {
            // setLoadingSync(true);
        }
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
            <div className="d-flex">
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) {
                            input.indeterminate = indeterminate;
                            input.checked = checked;
                        }
                    }}
                    onChange={onChange}
                />
                <span style={{ marginLeft: '8px' }}>({products_selected.length})</span>
            </div>
        ),
    };
    const uploadProduct = (templateId, otherImage) => {
        createProductsByTemplate({
            templateId,
            data: list.reduce((result, item) => {
                if (products_selected.includes(item._id)) {
                    const itemAdd = { ...item, main_images: [item.image], title: item.title };
                    if (otherImage?.length) itemAdd.main_images = [...(itemAdd.main_images || []), ...otherImage];
                    result.push(itemAdd);
                }
                return result;
            }, []),
        });
    };

    const handleChangeText = (data) => {
        setList((prev) => {
            prev = prev.map((item) => {
                if (!products_selected.includes(item._id)) return item;
                let title = item?.title;

                if (data?.textNew && data?.textOld) {
                    title = title?.replace(new RegExp(data?.textOld, "gi"), data?.textNew);
                }

                if (data?.textStart) title = `${data?.textStart} ${title}`;
                if (data?.textEnd) title = `${title} ${data?.textEnd}`;

                return { ...item, title };
            });
            return prev;
        });
    };

    const deleteProducts = () => {
        setList((prev) => [...prev?.filter((v) => !products_selected.includes(v._id))]);
    };

    const handleChangeSaleFilter = (name, value) => {
        setPage(1);
        setSizePerPage(50);
        setFilter((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex flex-md-row flex-column gap-2">
                    <div style={{ maxWidth: "450px" }} className="input-group mb-0">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Sale Min"
                            onChange={(e) =>
                                handleChangeSaleFilter("sale_min", e.target.value)
                            }
                            value={filter.sale_min || 0}
                        />
                        <span className="input-group-text">-</span>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Sale Max"
                            value={filter.sale_max || 0}
                            onChange={(e) =>
                                handleChangeSaleFilter("sale_max", e.target.value)
                            }
                        />
                    </div>
                    <button onClick={onClickExportData} className="btn btn-sm btn-success mr-1" hidden={true}><i className="ki-outline ki-exit-down"></i>Export</button>
                    <CSVLink filename={"data_amazon_" + dataExport.length + "_products.csv"} data={dataExport}>
                        <span ref={btnRef} />
                    </CSVLink>
                    <SelectBox
                        options={list_country}
                        name="filter_staff"
                        value={filter.country || ""}
                        onChange={(data) =>
                            setFilter((prev) => ({
                                ...prev,
                                country: data.value || "",
                            }))
                        }
                    />
                    <Select
                        name="dateType"
                        options={ListDateType}
                        className="al-select"
                        classNamePrefix="select"
                        value={ListDateType.filter(({ value }) => value === dateType)}
                        onChange={(e) => {
                            setDateType(e?.value || "");
                        }}
                        placeholder="Select a date ... "
                    />
                    {dateType === "custom" && filter?.dateStart && filter?.dateEnd ? (
                        <DateRangePicker
                            onCallback={handleEventDate}
                            initialSettings={{
                                startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                showDropdowns: true,
                                locale: { format: "DD/MM/YYYY" },
                            }}
                        >
                            <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                        </DateRangePicker>
                    ) : (
                        ""
                    )}
                    <button onClick={deleteProducts} type="button" className="btn btn-danger btn-sm me-1" disabled={products_selected.length <= 0}>
                        {t("Delete Products")}
                    </button>
                    <ModalChangeText onSubmit={handleChangeText} />
                    <ModalTemplate onSubmit={uploadProduct} />
                    {/* <ModalImport
                        data={products_selected}
                        allData={list}
                        template={list_template}
                        onSubmit={(data) => {
                            handleSubmitUpload(data)
                        }}
                    /> */}
                </div>
            </>
        );
    };

    return (
        <>
            <div className="card min-w-full">
                <Loading isLoading={isLoading} />
                <div className="card-body">
                    <div className="al-variants-table al-max-height-table-cus">
                        <ReactTable
                            columns={columns}
                            data={list || []}
                            groupButton={groupButton}
                            handleTableChange={handleTableChange}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            alClassName="table-layout-inherit al-d-none-option-react-select"
                            sizePerPageList={sizePerPageList}
                            isSelectRow={true}
                            selectRow={selectRow}
                            isCellEdit={true}
                            handleBeforeSaveCell={handleTableSaveCell}
                        />
                    </div>
                </div>
            </div>
        </>

    );
};

const mapStateToProps = ({ product_first_sale, productTemplate }) => ({ product_first_sale, productTemplate });
const mapDispatchToProps = {
    countString,
    formatterCreateAt,
    getTikTokFirstSales,
    // getProductTemplate,
    uploadTemplateByLink,
    createProductsByTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstSales);